import { IData } from '../components/Dashboard/Types'
import { GetAccessToken, LoginAuthData } from '../components/Types'
import * as actions from './actiontypes'
import {
  LEADS_DATA,
  SET_LEADS_COLUMN_DATA,
  LEADS_STATUS_DATA,
  LEADS_DETAILS_BY_ID,
  DOWNLOAD_LEAD_FILE,
  SEND_EMAIL,
  SEND_EMAIL_CAMPAIGN,
  BULK_EMAIL_DATA,
  UPLOAD_FILE,
  GET_EMAIL_TRACK_DATA,
  GET_EMAIL_DASHBOARD_DATA,
  GET_EMAIL_DETAILS_DATA,
  GET_EMAIL_GRAPH_DATA,
  ANALYTICS_GRAPH_DATA,
  ANALYTICS_BAR_GRAPH_DATA,
  ANALYTICS_NAME_DATA,
  CREATE_USER_DATA,
  UPDATE_USER_DATA,
  CHANGE_PASSWORD_DATA,
  GET_ADD_USER_DATA,
  GET_MANAGER_DATA,
  SET_TARGET_DATA,
  TARGET_GAUGE_DATA,
  EVENT_CALENDAR_DATA,
  ADD_CAMPAIGN_DATA,
  EDIT_CAMPAIGN_DATA,
  GET_CAMPAIGN_DATA,
  GET_CAMPAIGN_MAIL_LIST_DATA,
  GET_CAMPAIGN_NAME_DATA,
  GET_CAMPAIGN_DETAILS_DATA,
  REMOVE_LEAD_DATA,
  GET_CONTACTS_DATA,
  CREATE_CONTACTS_DATA,
  GET_CONTACT_DETAILS_DATA,
  UPLOAD_CONTACTS_FILE,
  CREATE_LEAD_DATA,
  UPDATE_LEAD_DATA,
  GET_FOLLOW_UP_DATA_BY_ID,
  CREATE_FOLLOW_UP_DATA,
  UPDATE_FOLLOW_UP_DATA,
  SEARCH_STRING,
  ANALYTICS_PIE_CHART_DATA,
  CAMPAIGN_EMAIL_CHART_DATA,
  GET_CAMPAIGN_LEADS_DATA,
  ADD_MACHINE_DATA,
  GET_MACHINE_DATA,
  ADD_ITEM_DATA,
  GET_MACHINE_NAMES,
  EDIT_MACHINE_DATA,
  EDIT_ITEM_DATA,
  GET_ITEM_DATA,
  GET_MACHINE_ALLOCATION_DATA,
  ALLOCATE_MACHINE_DATA,
  CAMPAIGN_INSIGHT
} from './actiontypes'

const {
  SUCCESS,
  REQUEST,
  FAILURE,
  RESET,
  GET_USER_DATA,
  LOGIN,
  LOGOUT,
  GET_ACCESS_TOKEN,
  LOGIN_USER,
  action,
} = actions

// Bellow are the Action Objects
export const getUserData = {
  request: () => action(GET_USER_DATA[REQUEST]),
  success: (response: IData) => action(GET_USER_DATA[SUCCESS], { response }),
  failure: () => action(GET_USER_DATA[FAILURE]),
  reset: () => action(GET_USER_DATA[RESET], {}),
}

export const getUserAuthentication = {
  request: (data: LoginAuthData) => action(LOGIN[REQUEST], { data }),
  success: (response: LoginAuthData) => action(LOGIN[SUCCESS], { response }),
  failure: () => action(LOGIN[FAILURE]),
  reset: () => action(LOGIN[RESET], {}),
}

//Create User Action
export const createUserDataAPICall = {
  request: (data: any) => action(CREATE_USER_DATA[REQUEST], { data }),
  success: (response: any) => action(CREATE_USER_DATA[SUCCESS], { response }),
  failure: () => action(CREATE_USER_DATA[FAILURE]),
  reset: () => action(CREATE_USER_DATA[RESET], {}),
}

export const updateUserDataAPICall = {
  request: (data: any) => action(UPDATE_USER_DATA[REQUEST], { data }),
  success: (response: any) => action(UPDATE_USER_DATA[SUCCESS], { response }),
  failure: () => action(UPDATE_USER_DATA[FAILURE]),
  reset: () => action(UPDATE_USER_DATA[RESET], {}),
}

export const changePasswordDataAPICall = {
  request: (data: any) => action(CHANGE_PASSWORD_DATA[REQUEST], { data }),
  success: (response: any) => action(CHANGE_PASSWORD_DATA[SUCCESS], { response }),
  failure: () => action(CHANGE_PASSWORD_DATA[FAILURE]),
  reset: () => action(CHANGE_PASSWORD_DATA[RESET], {}),
}

export const getAddUserDataAPICall = {
  request: (data: any) => action(GET_ADD_USER_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_ADD_USER_DATA[SUCCESS], { response }),
  failure: () => action(GET_ADD_USER_DATA[FAILURE]),
  reset: () => action(GET_ADD_USER_DATA[RESET], {}),
}

export const getManagerDataAPICall = {
  request: (data: any) => action(GET_MANAGER_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_MANAGER_DATA[SUCCESS], { response }),
  failure: () => action(GET_MANAGER_DATA[FAILURE]),
  reset: () => action(GET_MANAGER_DATA[RESET], {}),
}

export const setTargetDataAPICall = {
  request: (data: any) => action(SET_TARGET_DATA[REQUEST], { data }),
  success: (response: any) => action(SET_TARGET_DATA[SUCCESS], { response }),
  failure: () => action(SET_TARGET_DATA[FAILURE]),
  reset: () => action(SET_TARGET_DATA[RESET], {}),
}

//Contacts Action
export const getContactsDataAPICall = {
  request: (data: any) => action(GET_CONTACTS_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_CONTACTS_DATA[SUCCESS], { response }),
  failure: () => action(GET_CONTACTS_DATA[FAILURE]),
  reset: () => action(GET_CONTACTS_DATA[RESET], {}),
}

export const createContactsDataAPICall = {
  request: (data: any) => action(CREATE_CONTACTS_DATA[REQUEST], { data }),
  success: (response: any) => action(CREATE_CONTACTS_DATA[SUCCESS], { response }),
  failure: () => action(CREATE_CONTACTS_DATA[FAILURE]),
  reset: () => action(CREATE_CONTACTS_DATA[RESET], {}),
}

export const uploadContactsFileAPICall = {
  request: (data: any) => action(UPLOAD_CONTACTS_FILE[REQUEST], { data }),
  success: (response: any) => action(UPLOAD_CONTACTS_FILE[SUCCESS], { response }),
  failure: () => action(UPLOAD_CONTACTS_FILE[FAILURE]),
  reset: () => action(UPLOAD_CONTACTS_FILE[RESET], {}),
}

export const getContactDetailsDataAPICall = {
  request: (data: any) => action(GET_CONTACT_DETAILS_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_CONTACT_DETAILS_DATA[SUCCESS], { response }),
  failure: () => action(GET_CONTACT_DETAILS_DATA[FAILURE]),
  reset: () => action(GET_CONTACT_DETAILS_DATA[RESET], {}),
}

//Add Campign Action
export const addCampaignDataAPICall = {
  request: (data: any) => action(ADD_CAMPAIGN_DATA[REQUEST], { data }),
  success: (response: any) => action(ADD_CAMPAIGN_DATA[SUCCESS], { response }),
  failure: () => action(ADD_CAMPAIGN_DATA[FAILURE]),
  reset: () => action(ADD_CAMPAIGN_DATA[RESET], {}),
}

export const editCampaignDataAPICall = {
  request: (data: any) => action(EDIT_CAMPAIGN_DATA[REQUEST], { data }),
  success: (response: any) => action(EDIT_CAMPAIGN_DATA[SUCCESS], { response }),
  failure: () => action(EDIT_CAMPAIGN_DATA[FAILURE]),
  reset: () => action(EDIT_CAMPAIGN_DATA[RESET], {}),
}

export const getCampaignDataAPICall = {
  request: (data: any) => action(GET_CAMPAIGN_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_CAMPAIGN_DATA[SUCCESS], { response }),
  failure: () => action(GET_CAMPAIGN_DATA[FAILURE]),
  reset: () => action(GET_CAMPAIGN_DATA[RESET], {}),
}

export const getCampaignMailListDataAPICall = {
  request: (data: any) => action(GET_CAMPAIGN_MAIL_LIST_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_CAMPAIGN_MAIL_LIST_DATA[SUCCESS], { response }),
  failure: () => action(GET_CAMPAIGN_MAIL_LIST_DATA[FAILURE]),
  reset: () => action(GET_CAMPAIGN_MAIL_LIST_DATA[RESET], {}),
}

export const getCampaignDetailsDataAPICall = {
  request: (data: any) => action(GET_CAMPAIGN_DETAILS_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_CAMPAIGN_DETAILS_DATA[SUCCESS], { response }),
  failure: () => action(GET_CAMPAIGN_DETAILS_DATA[FAILURE]),
  reset: () => action(GET_CAMPAIGN_DETAILS_DATA[RESET], {}),
}

export const getCampaignLeadsDataAPICall = {
  request: (data: any) => action(GET_CAMPAIGN_LEADS_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_CAMPAIGN_LEADS_DATA[SUCCESS], { response }),
  failure: () => action(GET_CAMPAIGN_LEADS_DATA[FAILURE]),
  reset: () => action(GET_CAMPAIGN_LEADS_DATA[RESET], {}),
}

export const getCampaignNameAPICall = {
  request: (data: any) => action(GET_CAMPAIGN_NAME_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_CAMPAIGN_NAME_DATA[SUCCESS], { response }),
  failure: () => action(GET_CAMPAIGN_NAME_DATA[FAILURE]),
  reset: () => action(GET_CAMPAIGN_NAME_DATA[RESET], {}),
}

export const getCampaignEmailChartAPICall = {
  request: (data: any) => action(CAMPAIGN_EMAIL_CHART_DATA[REQUEST], { data }),
  success: (response: any) => action(CAMPAIGN_EMAIL_CHART_DATA[SUCCESS], { response }),
  failure: () => action(CAMPAIGN_EMAIL_CHART_DATA[FAILURE]),
  reset: () => action(CAMPAIGN_EMAIL_CHART_DATA[RESET], {}),
}

//Machine
export const addMachineDataAPICall = {
  request: (data: any) => action(ADD_MACHINE_DATA[REQUEST], { data }),
  success: (response: any) => action(ADD_MACHINE_DATA[SUCCESS], { response }),
  failure: () => action(ADD_MACHINE_DATA[FAILURE]),
  reset: () => action(ADD_MACHINE_DATA[RESET], {}),
}

export const editMachineDataAPICall = {
  request: (data: any) => action(EDIT_MACHINE_DATA[REQUEST], { data }),
  success: (response: any) => action(EDIT_MACHINE_DATA[SUCCESS], { response }),
  failure: () => action(EDIT_MACHINE_DATA[FAILURE]),
  reset: () => action(EDIT_MACHINE_DATA[RESET], {}),
}

export const getMachineDataAPICall = {
  request: (data: any) => action(GET_MACHINE_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_MACHINE_DATA[SUCCESS], { response }),
  failure: () => action(GET_MACHINE_DATA[FAILURE]),
  reset: () => action(GET_MACHINE_DATA[RESET], {}),
}

export const getItemDataAPICall = {
  request: (data: any) => action(GET_ITEM_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_ITEM_DATA[SUCCESS], { response }),
  failure: () => action(GET_ITEM_DATA[FAILURE]),
  reset: () => action(GET_ITEM_DATA[RESET], {}),
}

export const getMachineAllocationDataAPICall = {
  request: (data: any) => action(GET_MACHINE_ALLOCATION_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_MACHINE_ALLOCATION_DATA[SUCCESS], { response }),
  failure: () => action(GET_MACHINE_ALLOCATION_DATA[FAILURE]),
  reset: () => action(GET_MACHINE_ALLOCATION_DATA[RESET], {}),
}

export const addItemDataAPICall = {
  request: (data: any) => action(ADD_ITEM_DATA[REQUEST], { data }),
  success: (response: any) => action(ADD_ITEM_DATA[SUCCESS], { response }),
  failure: () => action(ADD_ITEM_DATA[FAILURE]),
  reset: () => action(ADD_ITEM_DATA[RESET], {}),
}

export const editItemDataAPICall = {
  request: (data: any) => action(EDIT_ITEM_DATA[REQUEST], { data }),
  success: (response: any) => action(EDIT_ITEM_DATA[SUCCESS], { response }),
  failure: () => action(EDIT_ITEM_DATA[FAILURE]),
  reset: () => action(EDIT_ITEM_DATA[RESET], {}),
}

export const allocateMachineDataAPICall = {
  request: (data: any) => action(ALLOCATE_MACHINE_DATA[REQUEST], { data }),
  success: (response: any) => action(ALLOCATE_MACHINE_DATA[SUCCESS], { response }),
  failure: () => action(ALLOCATE_MACHINE_DATA[FAILURE]),
  reset: () => action(ALLOCATE_MACHINE_DATA[RESET], {}),
}

export const getMachineNamesAPICall = {
  request: (data: any) => action(GET_MACHINE_NAMES[REQUEST], { data }),
  success: (response: any) => action(GET_MACHINE_NAMES[SUCCESS], { response }),
  failure: () => action(GET_MACHINE_NAMES[FAILURE]),
  reset: () => action(GET_MACHINE_NAMES[RESET], {}),
}

export const removeLeadDataAPICall = {
  request: (data: any) => action(REMOVE_LEAD_DATA[REQUEST], { data }),
  success: (response: any) => action(REMOVE_LEAD_DATA[SUCCESS], { response }),
  failure: () => action(REMOVE_LEAD_DATA[FAILURE]),
  reset: () => action(REMOVE_LEAD_DATA[RESET], {}),
}

//Action for Leads data
export const getLeadsDataAPICall = {
  request: (data: any) => action(LEADS_DATA[REQUEST], { data }),
  success: (response: any) => action(LEADS_DATA[SUCCESS], { response }),
  failure: () => action(LEADS_DATA[FAILURE]),
  reset: () => action(LEADS_DATA[RESET], {}),
}

export const setLeadsColumnDataAPICall = {
  request: (data: any) => action(SET_LEADS_COLUMN_DATA[REQUEST], { data }),
  success: (response: any) => action(SET_LEADS_COLUMN_DATA[SUCCESS], { response }),
  failure: () => action(SET_LEADS_COLUMN_DATA[FAILURE]),
  reset: () => action(SET_LEADS_COLUMN_DATA[RESET], {}),
}

export const getLeadsStatusDataAPICall = {
  request: (data: any) => action(LEADS_STATUS_DATA[REQUEST], { data }),
  success: (response: any) => action(LEADS_STATUS_DATA[SUCCESS], { response }),
  failure: () => action(LEADS_STATUS_DATA[FAILURE]),
  reset: () => action(LEADS_STATUS_DATA[RESET], {}),
}

export const getLeadsdetailsById = {
  request: (data: any) => action(LEADS_DETAILS_BY_ID[REQUEST], { data }),
  success: (response: any) => action(LEADS_DETAILS_BY_ID[SUCCESS], { response }),
  failure: () => action(LEADS_DETAILS_BY_ID[FAILURE]),
  reset: () => action(LEADS_DETAILS_BY_ID[RESET], {}),
}

export const getDownloadLeadFile = {
  request: (data: any) => action(DOWNLOAD_LEAD_FILE[REQUEST], { data }),
  success: (response: any) => action(DOWNLOAD_LEAD_FILE[SUCCESS], { response }),
  failure: () => action(DOWNLOAD_LEAD_FILE[FAILURE]),
  reset: () => action(DOWNLOAD_LEAD_FILE[RESET], {}),
}

export const getAnalyticsGraphDataAPICall = {
  request: (data: any) => action(ANALYTICS_GRAPH_DATA[REQUEST], { data }),
  success: (response: any) => action(ANALYTICS_GRAPH_DATA[SUCCESS], { response }),
  failure: () => action(ANALYTICS_GRAPH_DATA[FAILURE]),
  reset: () => action(ANALYTICS_GRAPH_DATA[RESET], {}),
}

export const getAnalyticsBarGraphDataAPICall = {
  request: (data: any) => action(ANALYTICS_BAR_GRAPH_DATA[REQUEST], { data }),
  success: (response: any) => action(ANALYTICS_BAR_GRAPH_DATA[SUCCESS], { response }),
  failure: () => action(ANALYTICS_BAR_GRAPH_DATA[FAILURE]),
  reset: () => action(ANALYTICS_BAR_GRAPH_DATA[RESET], {}),
}

export const getAnalyticsNameDataAPICall = {
  request: (data: any) => action(ANALYTICS_NAME_DATA[REQUEST], { data }),
  success: (response: any) => action(ANALYTICS_NAME_DATA[SUCCESS], { response }),
  failure: () => action(ANALYTICS_NAME_DATA[FAILURE]),
  reset: () => action(ANALYTICS_NAME_DATA[RESET], {}),
}

export const getTargetGaugeDataAPICall = {
  request: (data: any) => action(TARGET_GAUGE_DATA[REQUEST], { data }),
  success: (response: any) => action(TARGET_GAUGE_DATA[SUCCESS], { response }),
  failure: () => action(TARGET_GAUGE_DATA[FAILURE]),
  reset: () => action(TARGET_GAUGE_DATA[RESET], {}),
}

export const getTemperaturePieChartDataAPICall = {
  request: (data: any) => action(ANALYTICS_PIE_CHART_DATA[REQUEST], { data }),
  success: (response: any) => action(ANALYTICS_PIE_CHART_DATA[SUCCESS], { response }),
  failure: () => action(ANALYTICS_PIE_CHART_DATA[FAILURE]),
  reset: () => action(ANALYTICS_PIE_CHART_DATA[RESET], {}),
}

export const getEventCalendarDataAPICall = {
  request: (data: any) => action(EVENT_CALENDAR_DATA[REQUEST], { data }),
  success: (response: any) => action(EVENT_CALENDAR_DATA[SUCCESS], { response }),
  failure: () => action(EVENT_CALENDAR_DATA[FAILURE]),
  reset: () => action(EVENT_CALENDAR_DATA[RESET], {}),
}

//Action for Send email
export const sendEmailAPICall = {
  request: (data: any) => action(SEND_EMAIL[REQUEST], { data }),
  success: (response: any) => action(SEND_EMAIL[SUCCESS], { response }),
  failure: () => action(SEND_EMAIL[FAILURE], {}),
  reset: () => action(SEND_EMAIL[RESET], {}),
}

export const sendBulkEmailAPICall = {
  request: (data: any) => action(BULK_EMAIL_DATA[REQUEST], { data }),
  success: (response: any) => action(BULK_EMAIL_DATA[SUCCESS], { response }),
  failure: () => action(BULK_EMAIL_DATA[FAILURE]),
  reset: () => action(BULK_EMAIL_DATA[RESET], {}),
}

export const sendCampaignEmailAPICall = {
  request: (data: any) => action(SEND_EMAIL_CAMPAIGN[REQUEST], { data }),
  success: (response: any) => action(SEND_EMAIL_CAMPAIGN[SUCCESS], { response }),
  failure: () => action(SEND_EMAIL_CAMPAIGN[FAILURE]),
  reset: () => action(SEND_EMAIL_CAMPAIGN[RESET], {}),
}

//Upload File Action
export const uploadFileAPICall = {
  request: (data: any) => action(UPLOAD_FILE[REQUEST], { data }),
  success: (response: any) => action(UPLOAD_FILE[SUCCESS], { response }),
  failure: () => action(UPLOAD_FILE[FAILURE]),
  reset: () => action(UPLOAD_FILE[RESET], {}),
}

export const createLeadDataAPICall = {
  request: (data: any) => action(CREATE_LEAD_DATA[REQUEST], { data }),
  success: (response: any) => action(CREATE_LEAD_DATA[SUCCESS], { response }),
  failure: () => action(CREATE_LEAD_DATA[FAILURE]),
  reset: () => action(CREATE_LEAD_DATA[RESET], {}),
}

export const updateLeadDataAPICall = {
  request: (data: any) => action(UPDATE_LEAD_DATA[REQUEST], { data }),
  success: (response: any) => action(UPDATE_LEAD_DATA[SUCCESS], { response }),
  failure: () => action(UPDATE_LEAD_DATA[FAILURE]),
  reset: () => action(UPDATE_LEAD_DATA[RESET], {}),
}

export const getFollowUpDataByIdAPICall = {
  request: (data: any) => action(GET_FOLLOW_UP_DATA_BY_ID[REQUEST], { data }),
  success: (response: any) => action(GET_FOLLOW_UP_DATA_BY_ID[SUCCESS], { response }),
  failure: () => action(GET_FOLLOW_UP_DATA_BY_ID[FAILURE]),
  reset: () => action(GET_FOLLOW_UP_DATA_BY_ID[RESET], {}),
}

export const createFollowUpDataAPICall = {
  request: (data: any) => action(CREATE_FOLLOW_UP_DATA[REQUEST], { data }),
  success: (response: any) => action(CREATE_FOLLOW_UP_DATA[SUCCESS], { response }),
  failure: () => action(CREATE_FOLLOW_UP_DATA[FAILURE]),
  reset: () => action(CREATE_FOLLOW_UP_DATA[RESET], {}),
}

export const updateFollowUpDataAPICall = {
  request: (data: any) => action(UPDATE_FOLLOW_UP_DATA[REQUEST], { data }),
  success: (response: any) => action(UPDATE_FOLLOW_UP_DATA[SUCCESS], { response }),
  failure: () => action(UPDATE_FOLLOW_UP_DATA[FAILURE]),
  reset: () => action(UPDATE_FOLLOW_UP_DATA[RESET], {}),
}

//Action for Email Dashboard
export const getEmailTrackDataAPICall = {
  request: (data: any) => action(GET_EMAIL_TRACK_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_EMAIL_TRACK_DATA[SUCCESS], { response }),
  failure: () => action(GET_EMAIL_TRACK_DATA[FAILURE]),
  reset: () => action(GET_EMAIL_TRACK_DATA[RESET], {}),
}
export const getEmailDetailsDataAPICall = {
  request: (data: any) => action(GET_EMAIL_DETAILS_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_EMAIL_DETAILS_DATA[SUCCESS], { response }),
  failure: () => action(GET_EMAIL_DETAILS_DATA[FAILURE]),
  reset: () => action(GET_EMAIL_DETAILS_DATA[RESET], {}),
}
export const getEmailDashboardAPICall = {
  request: (data: any) => action(GET_EMAIL_DASHBOARD_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_EMAIL_DASHBOARD_DATA[SUCCESS], { response }),
  failure: () => action(GET_EMAIL_DASHBOARD_DATA[FAILURE]),
  reset: () => action(GET_EMAIL_DASHBOARD_DATA[RESET], {}),
}

export const getEmailGraphAPICall = {
  request: (data: any) => action(GET_EMAIL_GRAPH_DATA[REQUEST], { data }),
  success: (response: any) => action(GET_EMAIL_GRAPH_DATA[SUCCESS], { response }),
  failure: () => action(GET_EMAIL_GRAPH_DATA[FAILURE]),
  reset: () => action(GET_EMAIL_GRAPH_DATA[RESET], {}),
}

//Action for renewing access token
export const renewAccessToken = {
  request: (data: GetAccessToken) => action(GET_ACCESS_TOKEN[REQUEST], { data }),
  success: (response: GetAccessToken) => action(GET_ACCESS_TOKEN[SUCCESS], { response }),
  failure: () => action(GET_ACCESS_TOKEN[FAILURE]),
  reset: () => action(GET_ACCESS_TOKEN[RESET], {}),
}

export const getUserLogout = {
  request: () => action(LOGOUT[REQUEST]),
  success: () => action(LOGOUT[SUCCESS]),
  failure: () => action(LOGOUT[FAILURE]),
  reset: () => action(LOGOUT[RESET], {}),
}

export const getUserLoginUser = {
  request: (data: any) => action(LOGIN_USER[REQUEST], { data }),
  success: (response: any) => action(LOGIN_USER[SUCCESS], { response }),
  failure: () => action(LOGIN_USER[FAILURE]),
  reset: () => action(LOGIN_USER[RESET], {}),
}

export const getSearchString = {
  request: (data: any) => action(SEARCH_STRING[REQUEST], { data }),
  success: (response: any) => action(LOGIN_USER[SUCCESS], { response }),
  failure: () => action(LOGIN_USER[FAILURE]),
  reset: () => action(LOGIN_USER[RESET], {}),
}

export const campaignInsight = {
  request: (data: { id:string }) => action(CAMPAIGN_INSIGHT[REQUEST], { data }),
  success: (response: any) => action(CAMPAIGN_INSIGHT[SUCCESS], { response }),
  failure: () => action(CAMPAIGN_INSIGHT[FAILURE]),
  reset: () => action(CAMPAIGN_INSIGHT[RESET], {}),
}


export const actionTypes = {
  ...actions,
}
