import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { connect } from "react-redux";
import {
  addItemDataAPICall,
  addMachineDataAPICall,
  editItemDataAPICall,
  getAddUserDataAPICall,
  getManagerDataAPICall,
} from "../../actions";
import { dashboardEntity, machineDataEntity } from "../../reducers";
import { MultiSelect } from "primereact/multiselect";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

const EditItem = (props: any) => {
  const {
    editItemDataAPICall,
    getMachineNames,
    handleEditItemDialogClose,
    editMachineData,
  } = props;
  
  const selectedMachineIds = (editMachineData?.MachineItem.map((item:any) => item.machineMaster.id))
  
  const [selectedMachines, setSelectedMachines] = useState<number[]>(selectedMachineIds || "");
  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedToDate1, setSelectedToDate] = useState(
    moment().add(10, "days").format("YYYY-MM-DD")
  );
  
//   useEffect(() => {
//     setSelectedMachines(selectedMachineIds);
// }, [])

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      disabled: {
        cursor: "not-allowed",
      },
      datepicker1: {
        width: "425px",
        borderRadius: "20px",
      },
      datepicker2: {
        width: "418px",
        borderRadius: "20px",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "#ffffff",
        },
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#add8e6",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 4% !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "0px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "10% !important",
        wordBreak: "break-word",
      },
    });
  });

  const classes = useStyles();

  const handleChange = (e: any) => {
    setSelectedMachines(e.value);
  };

  const currentDate = selectedFromDate
    ? new Date(selectedFromDate)
    : new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDateSelected = dayjs(formattedCurrentDate);

  const toDateToday = selectedToDate1 ? new Date(selectedToDate1) : new Date();
  const formattedToDateToday = toDateToday.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDateSelected = dayjs(formattedToDateToday);

  const handleFromDateChange = (e: any) => {
    setSelectedFromDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleToDateChange = (e: any) => {
    setSelectedToDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const formik = useFormik({
    initialValues: {
      itemName: editMachineData?.name || "",
      category: editMachineData?.category || "",
      warranty: editMachineData?.warranty || "",
      lifespanInDays: editMachineData?.lifespanInDays || "",
      machine: editMachineData?.machine || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      itemName: Yup.string().required("Item Name is required"),
      category: Yup.string(),
      warranty: Yup.number()
        .typeError("Warranty must be a number")
        .min(0, "Warranty must be a positive number"),
      lifespanInDays: Yup.number()
        .typeError("Life Span must be a number")
        .min(0, "Life Span must be a positive number"),
      machine: Yup.string(),
    }),
    onSubmit: (values, event: any) => {},
  });

  const handleSubmit = () => {
    // const newlySelectedMachines = selectedMachines.filter(
    //   (id) => !selectedMachineIds.includes(id)
    // );
    let formdata = {
      itemId: editMachineData?.id,
      name: formik.values?.itemName,
      category: formik.values?.category,
      warranty: formik.values?.warranty,
      lifespanInDays: formik.values?.lifespanInDays,
      machineId: selectedMachines,
    };
    if (formdata) {
      editItemDataAPICall(formdata);
      handleEditItemDialogClose();
    }
  };

  const getErrorText = (error: any): string | undefined => {
    return typeof error === "string" ? error : undefined;
  };

  return (
    <>
      {/* <div
          className={classes.datepickercontainer}
          style={{ display: "flex", justifyContent: "space-between" }}
        > */}
      {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <Box sx={{ overflow: 'hidden',width: '50vw' }}>
                  <DatePicker
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                      },
                      "& .MuiInputBase-input": { height: "20px" },
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    }}
                    value={fromDateSelected}
                    label="Start Date"
                    onChange={handleFromDateChange}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
              <Box sx={{ overflow: 'hidden',width: '50vw' }}>
                  <DatePicker
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                      },
                      "& .MuiInputBase-input": { height: "20px" },
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    }}
                    label="End Date"
                    value={toDateSelected}
                    onChange={handleToDateChange}
                    minDate={fromDateSelected}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          </Grid> */}
      {/* </div> */}
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="itemName"
                name="itemName"
                label="Item Name"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.itemName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.itemName && Boolean(formik.errors.itemName)
                }
                helperText={
                  formik.touched.itemName
                    ? getErrorText(formik.errors.itemName)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="category"
                name="category"
                label="Category"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
                helperText={
                  formik.touched.category
                    ? getErrorText(formik.errors.category)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="warranty"
                name="warranty"
                label="Warranty"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.warranty}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.warranty && Boolean(formik.errors.warranty)
                }
                helperText={
                  formik.touched.warranty
                    ? getErrorText(formik.errors.warranty)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="lifespanInDays"
                name="lifespanInDays"
                label="Life Span"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.lifespanInDays}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lifespanInDays &&
                  Boolean(formik.errors.lifespanInDays)
                }
                helperText={
                  formik.touched.lifespanInDays
                    ? getErrorText(formik.errors.lifespanInDays)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* <div style={{ marginTop: "5px" }}>
              <StyledTextField
                select
                id="machine"
                name="machine"
                label="Machine"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "250px",
                      },
                    },
                  },
                }}
                value={formik.values.machine}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.machine && Boolean(formik.errors.machine)}
                helperText={
                  formik.touched.machine
                    ? getErrorText(formik.errors.machine)
                    : ""
                }
              >
                {getMachineNames?.map((machine: any) => (
                  <MenuItem key={machine.id} value={machine.id}>
                    {machine.name ? machine.name : "NA"}
                  </MenuItem>
                ))}
              </StyledTextField>
            </div> */}
            <div>
              <MultiSelect
                value={selectedMachines}
                options={getMachineNames?.data?.machineName}
                onChange={handleChange}
                optionLabel="name"
                optionValue="id"
                // display="chip"
                placeholder="Select Machines"
                className="w-full md:w-14rem custom-class"
                panelClassName="custom-dropdown-panel"
                filter
                style={{
                  width: "100%",
                  height: "40px",
                  color: "#000000",
                  borderRadius: "20px",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            className={classes.cancelbtn}
            color="primary"
            onClick={() => handleEditItemDialogClose()}
          >
            Cancel
          </Button>
          <span style={{ cursor: "not-allowed" }}>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </span>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getMachineNames: machineDataEntity.getMachine(state).getMachineNames,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    editItemDataAPICall: (data: any) =>
      dispatch(editItemDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItem);
