import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
  } from "@mui/material";
  import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import dayjs from "dayjs";
  import { searchBar } from '../Contacts/style';
  import CloseIcon from "@mui/icons-material/Close";
  import { createStyles, makeStyles } from "@material-ui/core";
  import { connect } from "react-redux";
  import { useEffect, useState } from "react";
  import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
  import { addItemDataAPICall, addMachineDataAPICall, editItemDataAPICall, editMachineDataAPICall, getItemDataAPICall, getMachineDataAPICall, getMachineNamesAPICall } from "../../actions";
  import { machineDataEntity, machineDataUI } from "../../reducers";
  import moment from "moment";
  import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
  import AccountTreeIcon from '@mui/icons-material/AccountTree';
  import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
  import AddMachines from "./AddMachines";
  import AddItem from "./AddItem";
  import { toast } from "react-toastify";
  import MachineDetails from "./MachineDetails";
  import EditItem from "./EditItem";
  import ItemPagination from "./ItemPagination";
  
  const Items = (props: any) => {
    const {
      getItemDataAPICall,
      getItemData,
      gettingMachineData,
      addMachineDataDone,
      editMachineDataDone,
      getMachineNamesAPICall,
      getAddMachineData,
      addItemDataDone,
      getAddItemData,
      getEditMachineData,
      editItemDataDone,
      getEditItemData,
      resetaddMachineDataAPICall,
      reseteditMachineDataAPICall,
      resetaddItemDataAPICall,
      reseteditItemDataAPICall,
    } = props;
  
    const [isContactsDetailsDialogOpen, setIsEmailDetailsDialogOpen] =
      useState<boolean>(false);
    const [isAddMachineDialogOpen, setIsAddMachineDialogOpen] =
      useState(false);
    const [isAddItemDialogOpen, setIsAddItemDialogOpen] =
      useState(false);
    const [isEditItemDialogOpen, setIsEditItemDialogOpen] =
      useState(false);
    const [machineDetails, setMachineDetails] = useState<string>();
    const [editMachine, setEditMachine] = useState<string>();
    const [limit, setLimit] = useState(10);
    const [pageChange, setPageChange] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedDate, setSelctedDate] = useState(
      moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
    );
    const [selectedToDate, setToSelctedDate] = useState(
      moment(new Date()).format("YYYY-MM-DD")
    );
  
    useEffect(() => {
        const machineData = {
          page: pageChange,
          limit: limit,
          searchString: searchTerm,
          fromdate: selectedDate,
          todate: selectedToDate,
        }
        getItemDataAPICall(machineData)
        getMachineNamesAPICall();
    }, [limit, selectedDate, selectedToDate, pageChange])
  
    useEffect(() => {
      const machineData = {
        page: pageChange,
        limit: limit,
        searchString: searchTerm,
        fromdate: selectedDate,
        todate: selectedToDate,
      }
      if (addMachineDataDone) {
        toast.success(getAddMachineData?.message);
        getItemDataAPICall(machineData)
        resetaddMachineDataAPICall();
      } else if (addItemDataDone) {
        toast.success(getAddItemData?.message);
        getItemDataAPICall(machineData)
        resetaddItemDataAPICall();
      } else if (editMachineDataDone) {
        toast.success(getEditMachineData?.message);
        getItemDataAPICall(machineData)
        reseteditMachineDataAPICall();
      } else if (editItemDataDone) {
        toast.success(getEditItemData?.message);
        getItemDataAPICall(machineData)
        getMachineNamesAPICall();
        reseteditItemDataAPICall();
      }
    }, [addMachineDataDone, addItemDataDone,editMachineDataDone, editItemDataDone]);
  
    const useStyles = makeStyles(() => {
      return createStyles({
        addUser: {
          borderRadius: "20px",
          fontFamily: "Montserrat-SemiBold",
          fontSize: "14px",
        },
        datepicker: {
          margin: "0px 0 0px 10px !important",
          width: "170px",
          borderRadius: "20px",
        },
        headerWrapper: {
          display: "flex",
          alignItems: "center",
          gap: "5px",
        },
        title: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        datepickercontainer: {
          display: "flex",
          alignItems: "center",
          backgroundColor: "#add8e6",
          margin: "5px",
          width: "99%",
          height: "55px",
        },
        subheading: {
          margin: "0 0 0 20px !important",
          fontFamily: "Montserrat-SemiBold !important",
          fontSize: "14px !important",
        },
        titletxt: {
          fontFamily: "Montserrat-SemiBold!important",
          fontSize: "18px!important",
          fontWeight: 500,
        },
        headingtxt: {
          color: "#000000",
          fontFamily: "Montserrat-SemiBold !important",
          fontSize: "22px!important",
        },
        search: {
          color: '#666666',
          cursor: 'pointer',
          position: 'absolute',
          top: 5,
          left: 5,
        },
        actionbtn: {
          height: "20px !important",
          width: "20px !important",
        },
        upload: {
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "38px",
          textTransform: "capitalize",
          border: "1px solid rgba(20, 56, 113, 1)",
          color: "#fff",
          background: "rgb(25, 60, 109)",
          borderRadius: "20px",
          fontFamily: "Montserrat-Medium",
          fontSize: "14px",
          fontWeight: "bold",
          width: "10%",
          whiteSpace: "nowrap",
      },
        addLead: {
          cursor: "pointer!important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center!important",
          height: "40px",
          textTransform: "capitalize",
          marginRight: "10px !important",
          border: "0",
          color: "#fff !important",
          background: "rgb(25, 60, 109)",
          borderRadius: "20px !important",
          fontFamily: "Montserrat-Medium !important",
          fontSize: "14px !important",
          width: "100% !important",
          wordBreak: "break-word",
        },
        progressBar: {
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      });
    });
    const classes = useStyles();
  
    const handleRowClick = (machine: string) => {
      setIsEmailDetailsDialogOpen(true);
      setMachineDetails(machine)
    }
  
    const handleEmailDetailsDialogClose = () => {
      setIsEmailDetailsDialogOpen(false);
    }
  
    const handleAddMachineDialogOpen = () => {
      setIsAddMachineDialogOpen(true);
    };
  
    const handleAddMachineDialogClose = () => {
      setIsAddMachineDialogOpen(false);
    };
  
    const handleAddItemDialogOpen = () => {
      setIsAddItemDialogOpen(true);
    };
  
    const handleAddItemDialogClose = () => {
      setIsAddItemDialogOpen(false);
    };
  
    const handleSearch = (event: any) => {
      const newSearchTerm = event.target.value;
      setSearchTerm(newSearchTerm);
  
      if (newSearchTerm === "") {
        const machineData = {
          page: pageChange,
          limit: limit,
          searchString: newSearchTerm,
          fromdate: selectedDate,
          todate: selectedToDate,
        }
        getItemDataAPICall(machineData)
      }
    };
  
    const handleKeyPress = (event: any) => {
      if (event.key === 'Enter') {
        const machineData = {
          page: pageChange,
          limit: limit,
          searchString: searchTerm,
          fromdate: selectedDate,
          todate: selectedToDate,
        }
        getItemDataAPICall(machineData)
      }
    };
  
    const handleLimitChange = (event: any) => {
      const newLimit = parseInt(event.target.value, 10);
      setLimit(newLimit);
    };
  
    const handleChangePage = (event: any,newPage: any) => {
      setPageChange(newPage);
    };
  
    const today = selectedDate ? new Date(selectedDate) : new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const fromDate = dayjs(formattedDate);
  
    const today1 = selectedToDate ? new Date(selectedToDate) : new Date();
    const formattedDate1 = today1.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const toDate = dayjs(formattedDate1);
    const onhandleChangeForFromDate = (e: any) => {
      setSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
    };
    const onhandleChangeForToDate = (e: any) => {
      setToSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
    };
  
    const handleEditItemDialogOpen = (item:string) => {
      setIsEditItemDialogOpen(true);
      setEditMachine(item);
      // getMachineNamesAPICall();
    };
  
    const handleEditItemDialogClose = () => {
      setIsEditItemDialogOpen(false);
    };
  
    return (
      <Paper sx={{ margin: "10px", minHeight: "300px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <Box sx={{ marginLeft: "16px" }}>
            <TextField
              id="search"
              name="search"
              type="text"
              placeholder="Search "
              InputLabelProps={{ style: { color: "#666666" } }}
              size="small"
              InputProps={{
                style: { padding: "0" },
                type: "search",
                startAdornment: (
                  <Tooltip title="Search" placement="top">
                    <span>
                      <SearchTwoToneIcon className={classes.search} />
                    </span>
                  </Tooltip>
                ),
              }}
              value={searchTerm}
              onChange={handleSearch}
              onKeyDown={handleKeyPress}
              sx={{ ...searchBar }}
            />
          </Box>
  
          <Typography
            className={classes.headingtxt}
            style={{
              position: "absolute",
              left: "48%",
              transform: "translateX(-50%)",
            }}
          >
            Items
          </Typography>
  
          <div style={{ display: "flex", alignItems: "center", marginRight: '10px' }}>
            <Tooltip title="Add Machine">
              <button
                className={classes.upload}
                onClick={handleAddMachineDialogOpen}
              >
                <PrecisionManufacturingOutlinedIcon sx={{ height: "50%" }} />
              </button>
            </Tooltip>
            <Tooltip title="Add Item">
              <button
                className={classes.upload}
                style={{marginLeft:'10px'}}
                onClick={handleAddItemDialogOpen}
              >
                <AccountTreeIcon sx={{ height: "50%" }} />
              </button>
            </Tooltip>
  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
                <Box className={classes.datepicker}>
                  <DatePicker
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                      },
                      "& .MuiOutlinedInput-input": {
                        paddingRight: "10px !important",
                        height: "18px",
                      },
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    label="From Date"
                    slotProps={{
                      textField: {
                        InputLabelProps: {
                          style: { top: "-8px" },
                        },
                      },
                    }}
                    value={fromDate}
                    onChange={onhandleChangeForFromDate}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
                <Box className={classes.datepicker}>
                  <DatePicker
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "20px",
                      },
                      "& .MuiOutlinedInput-input": {
                        paddingRight: "10px !important",
                        height: "18px",
                      },
                      "& .MuiFormLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    label="To Date"
                    slotProps={{
                      textField: {
                        InputLabelProps: {
                          style: { top: "-8px" },
                        },
                      },
                    }}
                    value={toDate}
                    onChange={onhandleChangeForToDate}
                    minDate={fromDate}
                  />
                </Box>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
        <div
          style={{
            margin: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
            minHeight: "360px",
          }}
        >
          <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
              >
                <TableRow>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Item Name</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Category</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Waranty</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Life Span</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gettingMachineData ? (
                  <TableRow>
                    <TableCell colSpan={12} className="tablebodycell">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100px",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : getItemData?.data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} className="tablebodycell">
                      <div
                        style={{
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No Data Available
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                    getItemData?.data?.map((item: any, index: any) => (
                    <TableRow
                      key={index}
                      sx={{ cursor: "pointer", background: "#ffffff" }}
                    //   onClick={() => handleRowClick(machine)}
                    >
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                      {item.name ? item.name : "NA"}
                      </TableCell>
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                      {item.category ? item.category : "NA"}
                      </TableCell>
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                      {item.warranty ? `${item.warranty} months` : "NA"}
                      </TableCell>
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                      {item.lifespanInDays ? `${item.lifespanInDays} days` : "NA"}
                      </TableCell>
                      <TableCell
                        className="tablebodycell"
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Tooltip title="Edit Item">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditItemDialogOpen(item)
                            }}
                            size="small"
                            sx={{ padding: 0 }}
                          >
                            <EditOutlinedIcon
                              sx={{ height: "18px", color: "#4CAF50" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <ItemPagination
              itemTableData={getItemData}
              handleChangePage={handleChangePage}
              handleLimitChange={handleLimitChange}
              pageChange={pageChange}
              limit={limit}
            />
          </TableContainer>
        </div>
  
        <Dialog
          open={isAddMachineDialogOpen}
          onClose={handleAddMachineDialogClose}
          fullWidth
          maxWidth={false}
          PaperProps={{
            style: {
              width: "50%",
              maxWidth: "50%",
            },
          }}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
            Add Machine
            <IconButton
              aria-label="close"
              onClick={handleAddMachineDialogClose}
              sx={{
                position: "absolute !important",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider className="divider" />
          <DialogContent sx={{ padding: "10px 15px !important" }}>
            <AddMachines
              handleAddMachineDialogClose={handleAddMachineDialogClose}
            />
          </DialogContent>
        </Dialog>
  
        <Dialog
          open={isEditItemDialogOpen}
          onClose={handleEditItemDialogClose}
          fullWidth
          maxWidth={false}
          PaperProps={{
            style: {
              width: "70%",
              maxWidth: "70%",
            },
          }}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
            Edit Item
            <IconButton
              aria-label="close"
              onClick={handleEditItemDialogClose}
              sx={{
                position: "absolute !important",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider className="divider" />
          <DialogContent sx={{ padding: "10px 15px !important" }}>
            <EditItem
              handleEditItemDialogClose={handleEditItemDialogClose}
              editMachineData={editMachine}
            />
          </DialogContent>
        </Dialog>
  
        <Dialog
          open={isAddItemDialogOpen}
          onClose={handleAddItemDialogClose}
          fullWidth
          maxWidth={false}
          PaperProps={{
            style: {
              width: "50%",
              maxWidth: "50%",
            },
          }}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
            Add Item
            <IconButton
              aria-label="close"
              onClick={handleAddItemDialogClose}
              sx={{
                position: "absolute !important",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider className="divider" />
          <DialogContent sx={{ padding: "10px 15px !important" }}>
            <AddItem
              handleAddItemDialogClose={handleAddItemDialogClose}
            />
          </DialogContent>
        </Dialog>
  
        <Dialog
          open={isContactsDetailsDialogOpen}
          onClose={handleEmailDetailsDialogClose}
          fullWidth
          maxWidth={false}
          PaperProps={{
            style: {
              width: "80%",
              maxWidth: "80%",
              margin: 'auto',
            },
          }}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
            Machine Details
            <IconButton
              aria-label="close"
              onClick={handleEmailDetailsDialogClose}
              sx={{
                position: "absolute !important",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider className="campaign-divider" />
          <DialogContent sx={{ padding: "10px 15px !important" }}>
            <MachineDetails
              machineDetailsData={machineDetails}
            />
          </DialogContent>
        </Dialog>
      </Paper>
    );
  };
  
  const mapStateToProps = (state: any) => {
    return {
      getItemData: machineDataEntity.getMachine(state).getItemData,
      gettingMachineData: machineDataUI.getMachine(state).gettingMachineData,
      getAddMachineData: machineDataEntity.getMachine(state).getAddMachineData,
      addMachineDataDone: machineDataUI.getMachine(state).addMachineDataDone,
      editMachineDataDone: machineDataUI.getMachine(state).editMachineDataDone,
      editItemDataDone: machineDataUI.getMachine(state).editItemDataDone,
      addItemDataDone: machineDataUI.getMachine(state).addItemDataDone,
      getAddItemData: machineDataEntity.getMachine(state).getAddItemData,
      getEditMachineData: machineDataEntity.getMachine(state).getEditMachineData,
      getEditItemData: machineDataEntity.getMachine(state).getEditItemData,
    };
  };
  
  const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
    return {
      getMachineNamesAPICall: (data: any) => dispatch(getMachineNamesAPICall.request(data)),
      getItemDataAPICall: (data: any) => dispatch(getItemDataAPICall.request(data)),
      resetaddMachineDataAPICall: () => dispatch(addMachineDataAPICall.reset()),
      reseteditMachineDataAPICall: () => dispatch(editMachineDataAPICall.reset()),
      reseteditItemDataAPICall: () => dispatch(editItemDataAPICall.reset()),
      resetaddItemDataAPICall: () => dispatch(addItemDataAPICall.reset()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Items);  