import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { searchBar } from '../Contacts/style';
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'
import { addItemDataAPICall, addMachineDataAPICall, editMachineDataAPICall, getMachineDataAPICall, getMachineNamesAPICall } from "../../actions";
import { machineDataEntity, machineDataUI } from "../../reducers";
import moment from "moment";
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddMachines from "./AddMachines";
import AddItem from "./AddItem";
import { toast } from "react-toastify";
import MachineDetails from "./MachineDetails";
import MachinePagination from "./MachinePagination";
import EditMachines from "./EditMachines";

const Machines = (props: any) => {
  const {
    getMachineDataAPICall,
    getMachineData,
    gettingMachineData,
    addMachineDataDone,
    editMachineDataDone,
    getMachineNamesAPICall,
    getAddMachineData,
    addItemDataDone,
    getAddItemData,
    getEditMachineData,
    resetaddMachineDataAPICall,
    reseteditMachineDataAPICall,
    resetaddItemDataAPICall,
  } = props;

  const [isContactsDetailsDialogOpen, setIsEmailDetailsDialogOpen] =
    useState<boolean>(false);
  const [isAddMachineDialogOpen, setIsAddMachineDialogOpen] =
    useState(false);
  const [isAddItemDialogOpen, setIsAddItemDialogOpen] =
    useState(false);
  const [isEditMachineDialogOpen, setIsEditMachineDialogOpen] =
    useState(false);
  const [machineDetails, setMachineDetails] = useState<string>();
  const [editMachine, setEditMachine] = useState<string>();
  const [limit, setLimit] = useState(10);
  const [pageChange, setPageChange] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelctedDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [selectedToDate, setToSelctedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  useEffect(() => {
      const machineData = {
        page: pageChange,
        limit: limit,
        searchString: searchTerm,
        fromdate: selectedDate,
        todate: selectedToDate,
      }
      getMachineDataAPICall(machineData)
  }, [limit, selectedDate, selectedToDate, pageChange])

  useEffect(() => {
    const machineData = {
      page: pageChange,
      limit: limit,
      searchString: searchTerm,
      fromdate: selectedDate,
      todate: selectedToDate,
    }
    if (addMachineDataDone) {
      toast.success(getAddMachineData?.message);
      getMachineDataAPICall(machineData)
      resetaddMachineDataAPICall();
    } else if (addItemDataDone) {
      toast.success(getAddItemData?.message);
      getMachineDataAPICall(machineData)
      resetaddItemDataAPICall();
    } else if (editMachineDataDone) {
      toast.success(getEditMachineData?.message);
      getMachineDataAPICall(machineData)
      reseteditMachineDataAPICall();
    }
  }, [addMachineDataDone, addItemDataDone,editMachineDataDone]);

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      headerWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
      },
      title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      titletxt: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "18px!important",
        fontWeight: 500,
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      search: {
        color: '#666666',
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        left: 5,
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      upload: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "38px",
        textTransform: "capitalize",
        border: "1px solid rgba(20, 56, 113, 1)",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        width: "10%",
        whiteSpace: "nowrap",
    },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "100% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  const handleRowClick = (machine: string) => {
    setIsEmailDetailsDialogOpen(true);
    setMachineDetails(machine)
  }

  const handleEmailDetailsDialogClose = () => {
    setIsEmailDetailsDialogOpen(false);
  }

  const handleAddMachineDialogOpen = () => {
    setIsAddMachineDialogOpen(true);
  };

  const handleAddMachineDialogClose = () => {
    setIsAddMachineDialogOpen(false);
  };

  const handleAddItemDialogOpen = () => {
    setIsAddItemDialogOpen(true);
    getMachineNamesAPICall();
  };

  const handleAddItemDialogClose = () => {
    setIsAddItemDialogOpen(false);
  };

  const handleSearch = (event: any) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === "") {
      const machineData = {
        page: pageChange,
        limit: limit,
        searchString: newSearchTerm,
        fromdate: selectedDate,
        todate: selectedToDate,
      }
      getMachineDataAPICall(machineData)
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      const machineData = {
        page: pageChange,
        limit: limit,
        searchString: searchTerm,
        fromdate: selectedDate,
        todate: selectedToDate,
      }
      getMachineDataAPICall(machineData)
    }
  };

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
  };

  const handleChangePage = (event: any,newPage: any) => {
    console.log("newPage",newPage);    
    setPageChange(newPage);
  };

  const today = selectedDate ? new Date(selectedDate) : new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDate = dayjs(formattedDate);

  const today1 = selectedToDate ? new Date(selectedToDate) : new Date();
  const formattedDate1 = today1.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDate = dayjs(formattedDate1);
  const onhandleChangeForFromDate = (e: any) => {
    setSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };
  const onhandleChangeForToDate = (e: any) => {
    setToSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleEditMachineDialogOpen = (machine:string) => {
    setIsEditMachineDialogOpen(true);
    setEditMachine(machine);
  };

  const handleEditMachineDialogClose = () => {
    setIsEditMachineDialogOpen(false);
  };

  return (
    <Paper sx={{ margin: "10px", minHeight: "300px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
        }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <TextField
            id="search"
            name="search"
            type="text"
            placeholder="Search "
            InputLabelProps={{ style: { color: "#666666" } }}
            size="small"
            InputProps={{
              style: { padding: "0" },
              type: "search",
              startAdornment: (
                <Tooltip title="Search" placement="top">
                  <span>
                    <SearchTwoToneIcon className={classes.search} />
                  </span>
                </Tooltip>
              ),
            }}
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
            sx={{ ...searchBar }}
          />
        </Box>

        <Typography
          className={classes.headingtxt}
          style={{
            position: "absolute",
            left: "48%",
            transform: "translateX(-50%)",
          }}
        >
          Machines
        </Typography>

        <div style={{ display: "flex", alignItems: "center", marginRight: '10px' }}>
          <Tooltip title="Add Machine">
            <button
              className={classes.upload}
              onClick={handleAddMachineDialogOpen}
            >
              <PrecisionManufacturingOutlinedIcon sx={{ height: "50%" }} />
            </button>
          </Tooltip>
          <Tooltip title="Add Item">
            <button
              className={classes.upload}
              style={{marginLeft:'10px'}}
              onClick={handleAddItemDialogOpen}
            >
              <AccountTreeIcon sx={{ height: "50%" }} />
            </button>
          </Tooltip>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiOutlinedInput-input": {
                      paddingRight: "10px !important",
                      height: "18px",
                    },
                    "& .MuiFormLabel-asterisk": {
                      color: "red",
                    },
                  }}
                  label="From Date"
                  slotProps={{
                    textField: {
                      InputLabelProps: {
                        style: { top: "-8px" },
                      },
                    },
                  }}
                  value={fromDate}
                  onChange={onhandleChangeForFromDate}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiOutlinedInput-input": {
                      paddingRight: "10px !important",
                      height: "18px",
                    },
                    "& .MuiFormLabel-asterisk": {
                      color: "red",
                    },
                  }}
                  label="To Date"
                  slotProps={{
                    textField: {
                      InputLabelProps: {
                        style: { top: "-8px" },
                      },
                    },
                  }}
                  value={toDate}
                  onChange={onhandleChangeForToDate}
                  minDate={fromDate}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div
        style={{
          margin: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
          minHeight: "360px",
        }}
      >
        <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
            >
              <TableRow>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Machine Name</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Model Number</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Maintenane (AMC)</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Created Date</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gettingMachineData ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              ) : getMachineData?.data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Available
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                getMachineData?.data?.map((machine: any, index: any) => (
                  <TableRow
                    key={index}
                    sx={{ cursor: "pointer", background: "#ffffff" }}
                    onClick={() => handleRowClick(machine)}
                  >
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                    {machine.name ? machine.name : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                    {machine.modelNumber ? machine.modelNumber : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                    {machine.defaultAMCInDays ? `${machine.defaultAMCInDays} days` : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ padding: "6px 16px !important" }}
                    >
                      {machine?.created_At
                        ? moment(machine?.created_At).format("MM/DD/YYYY")
                        : "NA"}
                    </TableCell>
                    <TableCell
                      className="tablebodycell"
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Tooltip title="Edit Machine">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditMachineDialogOpen(machine)
                          }}
                          size="small"
                          sx={{ padding: 0 }}
                        >
                          <EditOutlinedIcon
                            sx={{ height: "18px", color: "#4CAF50" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <MachinePagination
            machineTableData={getMachineData}
            handleChangePage={handleChangePage}
            handleLimitChange={handleLimitChange}
            pageChange={pageChange}
            limit={limit}
          />
        </TableContainer>
      </div>

      <Dialog
        open={isAddMachineDialogOpen}
        onClose={handleAddMachineDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "50%",
            maxWidth: "50%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
          Add Machine
          <IconButton
            aria-label="close"
            onClick={handleAddMachineDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <AddMachines
            handleAddMachineDialogClose={handleAddMachineDialogClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEditMachineDialogOpen}
        onClose={handleEditMachineDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "70%",
            maxWidth: "70%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
          Edit Machine
          <IconButton
            aria-label="close"
            onClick={handleEditMachineDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <EditMachines
            handleEditMachineDialogClose={handleEditMachineDialogClose}
            editMachineData={editMachine}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isAddItemDialogOpen}
        onClose={handleAddItemDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "50%",
            maxWidth: "50%",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
          Add Item
          <IconButton
            aria-label="close"
            onClick={handleAddItemDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <AddItem
            handleAddItemDialogClose={handleAddItemDialogClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isContactsDetailsDialogOpen}
        onClose={handleEmailDetailsDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: 'auto',
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", padding: '5px 24px' }}>
          Machine Details
          <IconButton
            aria-label="close"
            onClick={handleEmailDetailsDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="campaign-divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <MachineDetails
            machineDetailsData={machineDetails}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getMachineData: machineDataEntity.getMachine(state).getMachineData,
    gettingMachineData: machineDataUI.getMachine(state).gettingMachineData,
    getAddMachineData: machineDataEntity.getMachine(state).getAddMachineData,
    addMachineDataDone: machineDataUI.getMachine(state).addMachineDataDone,
    editMachineDataDone: machineDataUI.getMachine(state).editMachineDataDone,
    addItemDataDone: machineDataUI.getMachine(state).addItemDataDone,
    getAddItemData: machineDataEntity.getMachine(state).getAddItemData,
    getEditMachineData: machineDataEntity.getMachine(state).getEditMachineData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getMachineNamesAPICall: (data: any) => dispatch(getMachineNamesAPICall.request(data)),
    getMachineDataAPICall: (data: any) => dispatch(getMachineDataAPICall.request(data)),
    resetaddMachineDataAPICall: () => dispatch(addMachineDataAPICall.reset()),
    reseteditMachineDataAPICall: () => dispatch(editMachineDataAPICall.reset()),
    resetaddItemDataAPICall: () => dispatch(addItemDataAPICall.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Machines);  