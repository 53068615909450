import { FormControl, MenuItem, Pagination as MuiPagination, Select } from '@mui/material'
import Stack from '@mui/material/Stack'

const MachinePagination = (props: any) => {
  const { machineTableData, handleChangePage, pageChange, handleLimitChange,limit } = props    

  const checkPagination = () => {
    if (machineTableData?.totalPages > 0) {
      return 'pagination-required'
    } else {
      return 'pagination-not-required'
    }
  }

  switch (checkPagination()) {
    case 'pagination-not-required':
      return <></>
    case 'pagination-required':
      return (
        <div className='page-cont'>
          <div className='page-items'>
            <Stack>
              <MuiPagination
              size="small"
                count={machineTableData?.totalPages}
                variant='outlined'
                color='primary'
                page={pageChange}
                onChange={handleChangePage}
              />
            </Stack>
          </div>
          <FormControl sx={{height:'50%!important',width:'6% !important', marginLeft:'10px !important'}}>
                <Select
                sx={{borderRadius:'20px',fontSize:'10px'}}
                  labelId='limit-select-label'
                  id='limit-select'
                  value={limit}
                  onChange={handleLimitChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "150px",
                      },
                    },
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
        </div>        
      )
  }
}

export default MachinePagination
