import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { 
    ADD_MACHINE_DATA,
    GET_MACHINE_DATA,
    ADD_ITEM_DATA,
    GET_MACHINE_NAMES,
    EDIT_MACHINE_DATA,
    EDIT_ITEM_DATA,
    GET_ITEM_DATA,
    GET_MACHINE_ALLOCATION_DATA,
    ALLOCATE_MACHINE_DATA,
 } from '../actions/actiontypes'

const { REQUEST,  } = actionTypes


//write sagas function
function* handleAddMachineAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.AddMachineData,
        data.data,
      )
      yield sendPayload(apiResponse, ADD_MACHINE_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, ADD_MACHINE_DATA)
    }
  }

  function* handleEditMachineAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.EditMachineData,
        data.data,
      )
      yield sendPayload(apiResponse, EDIT_MACHINE_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, EDIT_MACHINE_DATA)
    }
  }

  function* handleEditItemAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.EditItemData,
        data.data,
      )
      yield sendPayload(apiResponse, EDIT_ITEM_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, EDIT_ITEM_DATA)
    }
  }

  function* handleAddItemAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.AddItemData,
        data.data,
      )
      yield sendPayload(apiResponse, ADD_ITEM_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, ADD_ITEM_DATA)
    }
  }

  function* handleGetMachineAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.GetMachineData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_MACHINE_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_MACHINE_DATA)
    }
  }

  function* handleGetMachineAllocationAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.GetMachineAllocationData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_MACHINE_ALLOCATION_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_MACHINE_ALLOCATION_DATA)
    }
  }

  function* handleGetItemAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.GetItemData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_ITEM_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_ITEM_DATA)
    }
  }

  function* handleAllocateMachineAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.AllocateMachineData,
        data.data,
      )
      yield sendPayload(apiResponse, ALLOCATE_MACHINE_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, ALLOCATE_MACHINE_DATA)
    }
  }

  function* handleGetMachineNamesAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.GetMachineNames,
        data.data,
      )
      yield sendPayload(apiResponse, GET_MACHINE_NAMES)
    } catch (e) {
      yield sendPayloadFailure(e, GET_MACHINE_NAMES)
    }
  }

export const sagas = {
  //watcher come here
  watchAddMachineAPI: takeLatest(actionTypes.ADD_MACHINE_DATA[REQUEST], handleAddMachineAPI),
  watchEditMachineAPI: takeLatest(actionTypes.EDIT_MACHINE_DATA[REQUEST], handleEditMachineAPI),
  watchGetMachineAPI: takeLatest(actionTypes.GET_MACHINE_DATA[REQUEST], handleGetMachineAPI),
  watchGetMachineAllocationAPI: takeLatest(actionTypes.GET_MACHINE_ALLOCATION_DATA[REQUEST], handleGetMachineAllocationAPI),
  watchGetItemAPI: takeLatest(actionTypes.GET_ITEM_DATA[REQUEST], handleGetItemAPI),
  watchAddItemAPI: takeLatest(actionTypes.ADD_ITEM_DATA[REQUEST], handleAddItemAPI),
  watchEditItemAPI: takeLatest(actionTypes.EDIT_ITEM_DATA[REQUEST], handleEditItemAPI),
  watchAllocateMachineAPI: takeLatest(actionTypes.ALLOCATE_MACHINE_DATA[REQUEST], handleAllocateMachineAPI),
  watchGetMachineNamesAPI: takeLatest(actionTypes.GET_MACHINE_NAMES[REQUEST], handleGetMachineNamesAPI),
}
