import React, { useEffect } from "react";
import CampaignDetails from "./CampaignDetails";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const CampaignDetailsWrapper = () => {
  const campaignDetails = useOutletContext();
  return (
    <Box className="campaign-details-wrapper">
      <CampaignDetails {...(campaignDetails as Object)} />
    </Box>
  );
};

export default CampaignDetailsWrapper;
