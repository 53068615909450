import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MultiSelect } from "primereact/multiselect";
// import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import "primereact/resources/primereact.min.css"; // Core CSS
// import 'primeicons/primeicons.css';  // Icons
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { connect } from "react-redux";
import {
  addItemDataAPICall,
  addMachineDataAPICall,
  allocateMachineDataAPICall,
  getAddUserDataAPICall,
  getManagerDataAPICall,
} from "../../actions";
import { dashboardEntity, machineDataEntity } from "../../reducers";
import { Dropdown } from "primereact/dropdown";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

const dropdownStyle = {
  width: "100%",
  height: "38px",
  color: "#000000",
  borderRadius: "20px",
}

const AllocateMachine = (props: any) => {
  const { allocateMachineDataAPICall, getMachineNames, handleAddItemDialogClose } =
    props;

  const [selectedMachines, setSelectedMachines] = useState<number[]>();
  const [selectedCustomer, setSelectedCustomer] = useState<string>();
  const [purchaseDate, setPurchaseDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [installationDate, setInstallationDate] = useState(
    moment().format("YYYY-MM-DD")
  );  

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      disabled: {
        cursor: "not-allowed",
      },
      datepicker1: {
        width: "425px",
        borderRadius: "20px",
      },
      datepicker2: {
        width: "418px",
        borderRadius: "20px",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "#ffffff",
        },
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#add8e6",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 4% !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "0px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "10% !important",
        wordBreak: "break-word",
      },
    });
  });

  const classes = useStyles();

  const handleChange = (e: any) => {
    setSelectedMachines(e.value);
  };

  const handleCustomerChange = (e: any) => {
    setSelectedCustomer(e.value);
  };

  const currentDate = purchaseDate
    ? new Date(purchaseDate)
    : new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const purchaseDateSelected = dayjs(formattedCurrentDate);

  const toDateToday = installationDate ? new Date(installationDate) : new Date();
  const formattedToDateToday = toDateToday.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const installationDateSelected = dayjs(formattedToDateToday);

  const handlePurchaseDateChange = (e: any) => {
    setPurchaseDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleInstallationDateChange = (e: any) => {
    setInstallationDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const formik = useFormik({
    initialValues: {
      itemName: "",
      category: "",
      maintenance: "",
      yearlyAMCCost: "",
      machine: "",
      costOfMachine: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      maintenance: Yup.number()
        .typeError("AMC must be a number")
        .min(0, "AMC must be a positive number"),
      yearlyAMCCost: Yup.number()
        .typeError("AMC Cost must be a number")
        .min(0, "AMC Cost must be a positive number"),
      costOfMachine: Yup.number()
        .typeError("Cost of machine must be a number")
        .min(0, "Cost of machine must be a positive number"),
    }),
    onSubmit: (values, event: any) => {},
  });

  const handleSubmit = () => {
    let formdata = {
      customerId: selectedCustomer,
      amcInDays: formik.values?.maintenance,
      yearlyAMCCost: formik.values?.yearlyAMCCost,
      machineMasterId: selectedMachines,
      dateOfPurchase:purchaseDate,
      dateOfInstallation:installationDate,
      costOfMachine: formik.values?.costOfMachine,
    };
    if (formdata) {
      allocateMachineDataAPICall(formdata);
      handleAddItemDialogClose();
    }
  };

  const getErrorText = (error: any): string | undefined => {
    return typeof error === "string" ? error : undefined;
  };

  return (
    <>
      {/* <div
        className={classes.datepickercontainer}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box sx={{ overflow: 'hidden',width: '50vw' }}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" },
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }}
                  value={fromDateSelected}
                  label="Start Date"
                  onChange={handleFromDateChange}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
            <Box sx={{ overflow: 'hidden',width: '50vw' }}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" },
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }}
                  label="End Date"
                  value={toDateSelected}
                  onChange={handleToDateChange}
                  minDate={fromDateSelected}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        </Grid>
      </div> */}
      <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Dropdown
                value={selectedCustomer}
                options={getMachineNames?.data?.user}
                onChange={handleCustomerChange}
                optionLabel="first_name"
                optionValue="id"
                placeholder="Select Customer"
                className="w-full md:w-14rem custom-class"
                panelClassName="custom-dropdown-panel"
                filter
                style={dropdownStyle}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <MultiSelect
                value={selectedMachines}
                options={getMachineNames?.data?.machineName}
                onChange={handleChange}
                optionLabel="name"
                optionValue="id"
                display="chip"
                placeholder="Select Machines"
                className="w-full md:w-14rem custom-class"
                panelClassName="custom-dropdown-panel"
                filter
                style={dropdownStyle}
              />
            </div>
          </Grid>
        </Grid>
        {/* <div
        className={classes.datepickercontainer}
        style={{ display: "flex", justifyContent: "space-between" }}
      > */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box sx={{ overflow: 'hidden',width: '50vw' }}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" },
                    '& .MuiFormLabel-asterisk': {
                      color: '#ffffff',
                    },
                  }}
                  value={purchaseDateSelected}
                  label="Purchase Date"
                  onChange={handlePurchaseDateChange}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box sx={{ overflow: 'hidden',width: '50vw' }}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" },
                    '& .MuiFormLabel-asterisk': {
                      color: '#ffffff',
                    },
                  }}
                  value={installationDateSelected}
                  label="Installation Date"
                  onChange={handleInstallationDateChange}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="maintenance"
                name="maintenance"
                label="Maintenance (AMC)"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.maintenance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.maintenance && Boolean(formik.errors.maintenance)
                }
                helperText={
                  formik.touched.maintenance
                    ? getErrorText(formik.errors.maintenance)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="yearlyAMCCost"
                name="yearlyAMCCost"
                label="Yearly AMC Cost"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.yearlyAMCCost}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.yearlyAMCCost &&
                  Boolean(formik.errors.yearlyAMCCost)
                }
                helperText={
                  formik.touched.yearlyAMCCost
                    ? getErrorText(formik.errors.yearlyAMCCost)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="costOfMachine"
                name="costOfMachine"
                label="Cost of Machine"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.costOfMachine}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.costOfMachine &&
                  Boolean(formik.errors.costOfMachine)
                }
                helperText={
                  formik.touched.costOfMachine
                    ? getErrorText(formik.errors.costOfMachine)
                    : ""
                }
              />
            </div>
          </Grid>
          </Grid>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            className={classes.cancelbtn}
            color="primary"
            onClick={() => handleAddItemDialogClose()}
          >
            Cancel
          </Button>
          <span style={{ cursor: "not-allowed" }}>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              type="submit"
              // disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </span>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getMachineNames: machineDataEntity.getMachine(state).getMachineNames,
    getManagerNameData: dashboardEntity.getDashboard(state).getManagerData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    allocateMachineDataAPICall: (data: any) =>
      dispatch(allocateMachineDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllocateMachine);
