import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  RESET,
  GET_MACHINE_DATA,
  GET_ITEM_DATA,
  ADD_MACHINE_DATA,
  ADD_ITEM_DATA,
  EDIT_MACHINE_DATA,
  EDIT_ITEM_DATA,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE,  } = actionTypes

const ui = () => {

  const gettingMachineData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_MACHINE_DATA[SUCCESS]:
        return false
      case GET_MACHINE_DATA[FAILURE]:
        return false
      case GET_MACHINE_DATA[REQUEST]:
        return true
      case GET_MACHINE_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const gettingItemData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_ITEM_DATA[SUCCESS]:
        return false
      case GET_ITEM_DATA[FAILURE]:
        return false
      case GET_ITEM_DATA[REQUEST]:
        return true
      case GET_ITEM_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const addMachineDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case ADD_MACHINE_DATA[SUCCESS]:
        return true
      case ADD_MACHINE_DATA[FAILURE]:
        return false
      case ADD_MACHINE_DATA[REQUEST]:
        return false
      case ADD_MACHINE_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const editMachineDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case EDIT_MACHINE_DATA[SUCCESS]:
        return true
      case EDIT_MACHINE_DATA[FAILURE]:
        return false
      case EDIT_MACHINE_DATA[REQUEST]:
        return false
      case EDIT_MACHINE_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const editItemDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case EDIT_ITEM_DATA[SUCCESS]:
        return true
      case EDIT_ITEM_DATA[FAILURE]:
        return false
      case EDIT_ITEM_DATA[REQUEST]:
        return false
      case EDIT_ITEM_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const addItemDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case ADD_ITEM_DATA[SUCCESS]:
        return true
      case ADD_ITEM_DATA[FAILURE]:
        return false
      case ADD_ITEM_DATA[REQUEST]:
        return false
      case ADD_ITEM_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  return combineReducers({
    gettingMachineData,
    gettingItemData,
    addMachineDataDone,
    addItemDataDone,
    editMachineDataDone,
    editItemDataDone,
  })
}

export default ui

export const getMachine = (state: RootState) => state.ui.machine
