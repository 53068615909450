import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { campaignDataEntity, campaignDataUI } from "../../reducers";
import CampaignEmailChart from "../Analytics/CampaignEmailChart";
import moment from "moment";
import SubjectCountChart from "../Analytics/SubjectCountChart";

const InsightCampaign = (props: any) => {
  const useStyles = makeStyles(() => {
    return createStyles({
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      headingtooltip: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "12px!important",
        fontWeight: "bold",
        wordBreak: "break-word",
      },
      heading: {
        // display: "flex",
        // justifyContent: "center",
        // marginBottom: "10px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      nodata: {
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "12px !important",
        color: "#000000",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginLeft: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
      },
      search: {
        color: "#666666",
        cursor: "pointer",
        position: "absolute",
        top: 5,
        left: 5,
      },
      mail: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "34px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "100%",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      progressBar: {
        position: "absolute",
        top: "30%",
        left: "30%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const {
    gettingCampaignEmailChartData,
    getCampaignEmailChartData,
    campaignInsightUi,
    campaignInsightData,
  } = props;
  const classes = useStyles();
  return (
    <Box display="flex" gap={"10px"}>
      <Card
        sx={{
          // marginBottom: "10px",
          // marginTop: "10px",
          // backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
          width: "60%",
          display: "flex",
          justifyContent: "center",
        }}>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          <Typography
              width={"100%"}
              textAlign={"center"}
              mb={"10px"}
              fontFamily={"Montserrat-SemiBold !important"}>
              Day Wise Open Mail Count
            </Typography>
        <TableContainer
          sx={{ marginBottom: "10px", width: "100%", height: "75vh" }}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}>
              <TableRow>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Subject</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Email Open Count</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Time</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignInsightUi ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}>
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                campaignInsightData?.data?.openedMail?.map(
                  (data: { subject: string; count: number; time: string }) => (
                    <TableRow>
                      <TableCell className="tablebodycell">
                        {data.subject}
                      </TableCell>
                      <TableCell className="tablebodycell">
                        {data.count}
                      </TableCell>
                      <TableCell className="tablebodycell">
                        {data.time
                          ? moment(data.time).format("MM/DD/YYYY")
                          : "NA"}
                      </TableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>
          {!campaignInsightData?.data?.openedMail?.length && !campaignInsightUi ? (
            <Typography
              width={"100%"}
              textAlign={"center"}
              mt={"20px"}
              fontFamily={"Montserrat-SemiBold !important"}>
              No Data
            </Typography>
          ) : (
            ""
          )}
        </TableContainer>
        </Box>
      </Card>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"10px"}
        width="calc(40% - 10px)">
        <Card
          sx={{
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
          }}>
          <CardContent sx={{ padding: "10px 16px" }}>
            {/* <Box> */}
            <Typography className={classes.heading}>
              Campaign Email Status
            </Typography>
            {gettingCampaignEmailChartData ? (
              <div
                style={{
                  height: "190px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <CircularProgress />
              </div>
            ) : getCampaignEmailChartData?.data?.length ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <Typography className={classes.heading}>Campaign Email Status</Typography> */}
                <CampaignEmailChart
                  getCampaignDetailsData={getCampaignEmailChartData}
                />
              </div>
            ) : (
              <div
                style={{
                  height: "190px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <span className={classes.nodata}>No data available</span>
              </div>
            )}
            {/* </Box> */}
            {/* <Typography className={classes.heading}>Campaign Email Status</Typography>
          <CampaignEmailChart 
          getCampaignDetailsData={getCampaignDetailsData}
        /> */}
          </CardContent>
        </Card>

        <Card
          sx={{
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
          }}>
          <CardContent sx={{ padding: "10px 16px" }}>
            {/* <Box> */}
            <Typography className={classes.heading}>Subject Wise Reply Count</Typography>
            {!campaignInsightData?.data?.replySubjectCount ? (
              <div
                style={{
                  height: "190px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <CircularProgress />
              </div>
            ) : campaignInsightData?.data?.replySubjectCount?.length ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <SubjectCountChart campaignInsightData={campaignInsightData} />
              </div>
            ) : (
              <div
                style={{
                  height: "190px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <span className={classes.nodata}>No data available</span>
              </div>
            )}
            {/* </Box> */}
            {/* <Typography className={classes.heading}>Campaign Email Status</Typography>
          <CampaignEmailChart 
          getCampaignDetailsData={getCampaignDetailsData}
        /> */}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    gettingCampaignEmailChartData:
      campaignDataUI.getCampaign(state).gettingCampaignEmailChartData,
    getCampaignEmailChartData:
      campaignDataEntity.getCampaign(state).getCampaignEmailGraphData,
    campaignInsightUi: campaignDataUI.getCampaign(state).campaignInsight,
    campaignInsightData: campaignDataEntity.getCampaign(state).campaignInsight,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightCampaign);
