import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { createStyles, makeStyles } from "@material-ui/core";
import "./Campaign.css";
import { connect } from "react-redux";
import {
  addCampaignDataAPICall,
  campaignInsight,
  editCampaignDataAPICall,
  getCampaignDataAPICall,
  getCampaignDetailsDataAPICall,
  getCampaignEmailChartAPICall,
  getCampaignLeadsDataAPICall,
  getLeadsStatusDataAPICall,
  removeLeadDataAPICall,
  sendCampaignEmailAPICall,
} from "../../actions";
import {
  campaignDataEntity,
  campaignDataUI,
  dashboardEntity,
  dashboardUI,
} from "../../reducers";
import AddCampaign from "./AddCampaign";
import { toast } from "react-toastify";
import moment from "moment";
import CampaignDetails from "./CampaignDetails";
import CampaignPagination from "./campaignPagination";
import SendEmailCampaign from "./SendEmailCampaign";
import EditCampaign from "./EditCampaign";
import { Outlet, useLocation } from "react-router-dom";
import InsightsIcon from "@mui/icons-material/Insights";
import InsightCampaign from "./InsightCampaign";

const style = {
  dialogStyle: {
    padding: "0.5rem 0",
    position: "fixed",
    top: "40px",
    left: "calc(50% - 480px)",
    height: "fit-content",
    maxHeight: "100vh",
    zIndex: "10000",
    bgcolor: "#fff",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    overflow: "scroll",
    borderRadius: "3px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  bialogBg: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "max(100%,100vh)",
    zIndex: "9999",
    bgcolor: "black",
    opacity: "0.5",
  },
};

const Campaign = (props: any) => {
  const {
    getCampaignDataAPICall,
    isCampaignSendEmailDone,
    getCampaignDetailsDataAPICall,
    sendEmailCampaignData,
    resetsendCampaignEmailAPICall,
    getCampaignData,
    getCampaignMailListData,
    getRemoveLeadData,
    isRemovingLeadDataDone,
    gettingAddCampaignData,
    isEditingCampaignData,
    getCampaignDetailsData,
    getAddCampaignData,
    resetAddCampaign,
    resetRemoveLead,
    isAddCampaignDataDone,
    isEditCampaignDataDone,
    getEditCampaignData,
    resetEditCampaign,
    gettingCampaignData,
    getCampaignEmailChartAPICall,
    getCampaignLeadsDataAPICall,
    getLeadsStatusDataAPICall,
    getCampaignInsightData,
  } = props;

  const [isCreateCampaignDialogOpen, setIsCreateCampaignDialogOpen] =
    useState(false);
  const [isEditCampaignDialogOpen, setIsEditCampaignDialogOpen] =
    useState(false);
  const [isCampaignDetailsDialogOpen, setIsCampaignDetailsDialogOpen] =
    useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [handleEmailBox, setHandleEmailBox] = useState({
    campaignId: "",
    leadNames: [],
    emailIds: [],
  });
  const [campaignId, setcampaignId] = useState(" ");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [emailLogs, setEmailLogs] = useState<any[]>([]);
  const [limit, setLimit] = useState(100);
  const [pageChange, setPageChange] = useState(1);
  const [dialogClosed, setDialogClosed] = useState(false);
  const [selectedBounceType, setParentSelectedBounceType] = useState<
    { label: string; value: string } | string
  >("");
  const [route, setRoute] = useState(
    window.location.pathname.split("/").filter(Boolean).pop()
  );
  const [insight, setInsight] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const route = window.location.pathname.split("/").filter(Boolean).pop();
    setRoute(route);
    const id = queryParams.get("id");
    if (route === "campaign") {
      window.sessionStorage.removeItem("campaign-detail-id");
      setcampaignId("");
      handleCanpaignDetailsDialogClose();
    } else if (route === "campaign-details" && id) {
      handleRowClick(id);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const campaignData = {
      page: pageChange,
      limit: limit,
    };
    getCampaignDataAPICall(campaignData);
  }, [limit, pageChange]);

  useEffect(() => {
    if (getCampaignMailListData) {
      setEmailLogs(getCampaignMailListData?.emailLogs);
    }
  }, [getCampaignMailListData]);

  useEffect(() => {
    if (campaignId !== " ") {
      const emailStatus =
        selectedBounceType === "All" ? "" : selectedBounceType;
      let data = {
        id: campaignId,
        page: page,
        pageSize: pageLimit,
        mailFilter: emailStatus,
      };
      getCampaignDetailsDataAPICall(data);
    }
  }, [page, pageLimit, selectedBounceType]);

  useEffect(() => {
    if (isAddCampaignDataDone) {
      if (getAddCampaignData?.error?.length) {
        toast.error(getAddCampaignData?.message);
      } else {
        toast.success(getAddCampaignData?.message);
      }
      const campaignData = {
        page: pageChange,
        limit: limit,
      };
      getCampaignDataAPICall(campaignData);
      setIsCreateCampaignDialogOpen(false);
      resetAddCampaign();
    } else if (isCampaignSendEmailDone) {
      if (sendEmailCampaignData?.error?.length) {
        toast.error(sendEmailCampaignData?.message);
      } else {
        toast.success(sendEmailCampaignData?.message);
      }
      resetsendCampaignEmailAPICall();
    } else if (isRemovingLeadDataDone) {
      let data = {
        id: campaignId,
      };
      getCampaignDetailsDataAPICall(data);
      toast.success(getRemoveLeadData?.message);
      resetRemoveLead();
    } else if (isEditCampaignDataDone) {
      if (getEditCampaignData?.error?.length) {
        toast.error(getEditCampaignData?.message);
      } else {
        toast.success(getEditCampaignData?.message);
      }
      const campaignData = {
        page: pageChange,
        limit: limit,
      };
      getCampaignDataAPICall(campaignData);
      setIsEditCampaignDialogOpen(false);
      resetEditCampaign();
    }
  }, [
    isAddCampaignDataDone,
    isRemovingLeadDataDone,
    isCampaignSendEmailDone,
    isEditCampaignDataDone,
  ]);

  const handlePageSizeChange = (newPageSize: any) => {
    setPage(newPageSize);
  };

  const handlePageLimitChange = (newPageLimit: any) => {
    setPageLimit(newPageLimit);
  };
  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "100% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();

  const handleSelectedBounceTypeChange = (
    newSelectedBounceType: { label: string; value: string } | string
  ) => {
    setParentSelectedBounceType(newSelectedBounceType);
  };

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage);
  };

  const handleRowClick = (id: any) => {
    setcampaignId(id);
    setIsCampaignDetailsDialogOpen(true);
    getCampaignEmailChartAPICall({ id: id });
    let data = {
      id: id,
      page: page,
      pageSize: pageLimit,
    };
    getCampaignDetailsDataAPICall(data);
    getCampaignLeadsDataAPICall({ id: id });

    window.sessionStorage.setItem("campaign-detail-id", id);
    if (route === "campaign") {
      window.open(`/home/campaign/campaign-details?id=${id}`, "_blank");
    }

    // const statusData = {
    //   type: "3"
    // };
    // getLeadsStatusDataAPICall(statusData)
  };

  const handleCanpaignDetailsDialogClose = () => {
    setIsCampaignDetailsDialogOpen(false);
    setPageLimit(10);
    setEmailLogs([]);
    setDialogClosed(true);
  };

  const handleCreateCanpaignDialogOpen = () => {
    setIsCreateCampaignDialogOpen(true);
  };

  const handleCreateCanpaignDialogClose = () => {
    setIsCreateCampaignDialogOpen(false);
  };

  const handleEditCampaignDialogOpen = (id: string) => {
    setIsEditCampaignDialogOpen(true);
    let data = {
      id: id,
      page: page,
      pageSize: pageLimit,
    };
    getCampaignLeadsDataAPICall(data);
  };

  const handleInsightCampaignDialogOpen = (id: string) => {
    setInsight(id);
    getCampaignEmailChartAPICall({ id: id });
    getCampaignInsightData({ id });
  };

  const handleEditCampaignDialogClose = () => {
    setIsEditCampaignDialogOpen(false);
  };

  const handleSendEmailCloseDialog = () => {
    setOpenSendEmailDialog(false);
    setHandleEmailBox({ campaignId: "", leadNames: [], emailIds: [] });
  };

  return (
    <>
      {handleEmailBox?.campaignId !== "" && (
        <>
          <Box
            sx={{
              ...style.bialogBg,
              display: handleEmailBox?.campaignId !== "" ? "block" : "none",
            }}></Box>
          <Box
            sx={{
              ...style.dialogStyle,
              display: handleEmailBox?.campaignId !== "" ? "block" : "none",
            }}>
            <DialogTitle
              sx={{ padding: "0 15px 10px !important" }}
              className={classes.addcomment}>
              Send Email
              <IconButton
                aria-label="close"
                onClick={handleSendEmailCloseDialog}
                sx={{
                  position: "absolute !important",
                  right: "15px",
                  top: 8,
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <SendEmailCampaign
              leadNames={handleEmailBox?.leadNames}
              emailIds={handleEmailBox?.emailIds}
              campaignId={handleEmailBox?.campaignId}
              handleCloseCampaign={handleSendEmailCloseDialog}
              setHandleEmailBox={setHandleEmailBox}
            />
          </Box>
        </>
      )}
      {route === "campaign" ? (
        <Paper sx={{ margin: "10px", minHeight: "300px" }}>
          {gettingCampaignData ||
            gettingAddCampaignData ||
            (isEditingCampaignData && <div className="overlay" />)}
          {gettingCampaignData ||
            gettingAddCampaignData ||
            (isEditingCampaignData && (
              <CircularProgress
                color="primary"
                className={classes.progressBar}
              />
            ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Typography
                className={classes.headingtxt}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  transform: "translateX(85px)",
                }}>
                Campaigns
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="primary"
                className={classes.addLead}
                sx={{ borderRadius: "20px", margin: "10px" }}
                onClick={() => handleCreateCanpaignDialogOpen()}>
                <AddOutlinedIcon sx={{ height: "60%" }} />
                Create Campaign
              </Button>
            </div>
          </div>
          <div
            style={{
              margin: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
              minHeight: "360px",
            }}>
            <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Campaign Name</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Type</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Status</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Start Date</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">End Date</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Owner</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gettingCampaignData ? (
                    <TableRow>
                      <TableCell colSpan={12} className="tablebodycell">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                          }}>
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : getCampaignData?.data?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={12} className="tablebodycell">
                        <div
                          style={{
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          No Data Available
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    getCampaignData?.data?.map((campaign: any, index: any) => (
                      <TableRow
                        key={index}
                        sx={{ cursor: "pointer", background: "#ffffff" }}
                        onClick={() => handleRowClick(campaign.id)}>
                        <TableCell
                          className="tablebodycell"
                          sx={{ padding: "6px 16px !important" }}>
                          {campaign.name ? campaign.name : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycell"
                          sx={{ padding: "6px 16px !important" }}>
                          {campaign.type ? campaign.type : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycell"
                          sx={{ padding: "6px 16px !important" }}>
                          {campaign.status ? campaign.status : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycell"
                          sx={{ padding: "6px 16px !important" }}>
                          {campaign.start_date
                            ? moment(campaign.start_date).format("MM/DD/YYYY")
                            : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycell"
                          sx={{ padding: "6px 16px !important" }}>
                          {campaign.end_date
                            ? moment(campaign.end_date).format("MM/DD/YYYY")
                            : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycell"
                          sx={{ padding: "6px 16px !important" }}>
                          {campaign?.created_by ? campaign?.created_by : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycell"
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          <Box display="flex" gap={"8px"}>
                            <Tooltip title="Edit Campaign">
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditCampaignDialogOpen(campaign.id);
                                }}
                                // onClick={(e) => {
                                //   stopPropagation(e);
                                //   handleOpen(
                                //     getCampaignDetailsData?.data?.id,
                                //     campaign?.users?.id
                                //   );
                                // }}
                                size="small"
                                sx={{ padding: 0 }}>
                                <EditOutlinedIcon
                                  sx={{ height: "18px", color: "#4CAF50" }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Insights">
                              <IconButton
                                sx={{ padding: 0 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleInsightCampaignDialogOpen(campaign.id);
                                }}>
                                <InsightsIcon
                                  sx={{
                                    fontSize: "20px",
                                    color: "rgb(25, 60, 109)",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              <CampaignPagination
                campaignTableData={getCampaignData}
                handleChangePage={handleChangePage}
                handleLimitChange={handleLimitChange}
                pageChange={pageChange}
                limit={limit}
              />
            </TableContainer>
          </div>
          <Dialog
            open={isCreateCampaignDialogOpen}
            onClose={handleCreateCanpaignDialogClose}
            fullWidth
            maxWidth={false}
            PaperProps={{
              style: {
                width: "70%",
                maxWidth: "70%",
              },
            }}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 24px",
              }}>
              Add Campaign
              <IconButton
                aria-label="close"
                onClick={handleCreateCanpaignDialogClose}
                sx={{
                  position: "absolute !important",
                  right: 8,
                  top: 8,
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <DialogContent sx={{ padding: "10px 15px !important" }}>
              <AddCampaign
                handleCreateCanpaignDialogClose={
                  handleCreateCanpaignDialogClose
                }
              />
            </DialogContent>
          </Dialog>

          <Dialog
            open={!!insight}
            fullWidth
            maxWidth={false}
            PaperProps={{
              style: {
                width: "100%",
                maxWidth: "100%",
              },
            }}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 24px",
              }}>
              Insights
              <IconButton
                aria-label="close"
                onClick={() => setInsight("")}
                sx={{
                  position: "absolute !important",
                  right: 8,
                  top: 8,
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <DialogContent sx={{ padding: "20px 2.5% 0 2%!important" }}>
              <InsightCampaign />
            </DialogContent>
          </Dialog>

          <Dialog
            open={isEditCampaignDialogOpen}
            onClose={handleEditCampaignDialogClose}
            fullWidth
            maxWidth={false}
            PaperProps={{
              style: {
                width: "70%",
                maxWidth: "70%",
              },
            }}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 24px",
              }}>
              Edit Campaign
              <IconButton
                aria-label="close"
                onClick={handleEditCampaignDialogClose}
                sx={{
                  position: "absolute !important",
                  right: 8,
                  top: 8,
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <DialogContent sx={{ padding: "10px 15px !important" }}>
              <EditCampaign
                handleEditCampaignDialogClose={handleEditCampaignDialogClose}
              />
            </DialogContent>
          </Dialog>

          <Dialog
            open={false && handleEmailBox?.campaignId === ""}
            onClose={handleCanpaignDetailsDialogClose}
            fullWidth
            maxWidth={false}
            PaperProps={{
              style: {
                width: "80%",
                maxWidth: "80%",
                margin: "auto",
              },
            }}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 24px",
              }}>
              Campaign Details
              <IconButton
                aria-label="close"
                onClick={handleCanpaignDetailsDialogClose}
                sx={{
                  position: "absolute !important",
                  right: 8,
                  top: 8,
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="campaign-divider" />
            <DialogContent sx={{ padding: "10px 15px !important" }}>
              <CampaignDetails
                getCampaignDetailsData={getCampaignDetailsData}
                handleCampaignDetailsDialogClose={
                  handleCanpaignDetailsDialogClose
                }
                onPageSizeChange={handlePageSizeChange}
                onPageLimitChange={handlePageLimitChange}
                setHandleEmailBox={setHandleEmailBox}
                onSelectedBounceTypeChange={handleSelectedBounceTypeChange}
                campaignId1={campaignId}
                page={page}
                pageLimit={pageLimit}
                emailLogs={emailLogs}
                dialogClosed={dialogClosed}
              />
            </DialogContent>
          </Dialog>
        </Paper>
      ) : (
        <Outlet
          context={{
            getCampaignDetailsData: getCampaignDetailsData,
            handleCampaignDetailsDialogClose: handleCanpaignDetailsDialogClose,
            onPageSizeChange: handlePageSizeChange,
            onPageLimitChange: handlePageLimitChange,
            setHandleEmailBox: setHandleEmailBox,
            onSelectedBounceTypeChange: handleSelectedBounceTypeChange,
            campaignId1: campaignId,
            page: page,
            pageLimit: pageLimit,
            emailLogs: emailLogs,
            dialogClosed: dialogClosed,
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    gettingCampaignData: campaignDataUI.getCampaign(state).gettingCampaignData,
    getAddCampaignData:
      campaignDataEntity.getCampaign(state).getAddCampaignData,
    getEditCampaignData:
      campaignDataEntity.getCampaign(state).getEditCampaignData,
    isRemovingLeadDataDone:
      campaignDataUI.getCampaign(state).isRemovingLeadDataDone,
    getCampaignMailListData:
      campaignDataEntity.getCampaign(state).getCampaignMailListData,
    getRemoveLeadData: campaignDataEntity.getCampaign(state).getRemoveLeadData,
    getCampaignData: campaignDataEntity.getCampaign(state).getCampaignData,
    getCampaignDetailsData:
      campaignDataEntity.getCampaign(state).getCampaignDetailsData,
    gettingAddCampaignData:
      campaignDataUI.getCampaign(state).gettingAddCampaignData,
    isEditingCampaignData:
      campaignDataUI.getCampaign(state).isEditingCampaignData,
    isAddCampaignDataDone:
      campaignDataUI.getCampaign(state).isAddCampaignDataDone,
    isEditCampaignDataDone:
      campaignDataUI.getCampaign(state).isEditCampaignDataDone,
    isCampaignSendEmailDone:
      dashboardUI.getDashboard(state).isCampaignSendEmailDone,
    sendEmailCampaignData:
      dashboardEntity.getDashboard(state).sendEmailCampaignData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getCampaignDataAPICall: (data: any) =>
      dispatch(getCampaignDataAPICall.request(data)),
    getCampaignEmailChartAPICall: (data: any) =>
      dispatch(getCampaignEmailChartAPICall.request(data)),
    getCampaignDetailsDataAPICall: (data: any) =>
      dispatch(getCampaignDetailsDataAPICall.request(data)),
    getCampaignLeadsDataAPICall: (data: any) =>
      dispatch(getCampaignLeadsDataAPICall.request(data)),
    getLeadsStatusDataAPICall: (data: any) =>
      dispatch(getLeadsStatusDataAPICall.request(data)),
    resetRemoveLead: () => dispatch(removeLeadDataAPICall.reset()),
    resetAddCampaign: () => dispatch(addCampaignDataAPICall.reset()),
    resetEditCampaign: () => dispatch(editCampaignDataAPICall.reset()),
    resetsendCampaignEmailAPICall: () =>
      dispatch(sendCampaignEmailAPICall.reset()),
    getCampaignInsightData: (data: { id: string }) =>
      dispatch(campaignInsight.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
