import { combineReducers } from 'redux'
import * as dashboardState from './dashboard'
import * as userAuthenticationState from './userAuthentication'
import * as loaderState from './loaderState'
import * as loginUserState from './loginUser'
import * as leadsState from './Leads'
import * as analyticsState from './analytics'
import * as campaignsState from './campaign'
import * as machinesState from './machine'
import * as contactsState from './contact'

export {
  dashboardState,
  userAuthenticationState,
  loaderState,
  loginUserState,
  leadsState,
  analyticsState,
  campaignsState,
  machinesState,
  contactsState,
}

export default combineReducers({
  leads: leadsState.default(),
  analytics: analyticsState.default(),
  campaign: campaignsState.default(),
  machine: machinesState.default(),
  contact: contactsState.default(),

  dashboard: dashboardState.default(),
  userAuthentication: userAuthenticationState.default(),
  loaderState: loaderState.default(),
  loginUser: loginUserState.default(),

})
