import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { contactDataUI, dashboardEntity, dashboardUI } from "../../reducers";

const MachineDetails = (props: any) => {
  const {
    machineDetailsData,
    isGettingEmailDetailsData,
  } = props;  

  const useStyles = makeStyles(() => {
    return createStyles({
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      headingtooltip: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "12px!important",
        fontWeight: "bold",
        wordBreak: "break-word",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginLeft: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
      },
      search: {
        color: '#666666',
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        left: 5,
      },
      mail: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "34px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "100%",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  return (
    <>
      <Card
        sx={{
          marginTop: "15px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <Typography
          className={classes.titletext}
          sx={{ margin: "1% 0 0 2%" }}
        >
          Item Details
        </Typography>
        <CardContent
          sx={{ padding: "0px 16px 10px 16px", paddingBottom: "5px !important" }}
        >
          <Typography
            className={classes.headingtext}
            sx={{ paddingTop: "0 !important", marginTop: "10px" }}
          >
            {/* Email Status */}
          </Typography>
          <TableContainer sx={{ backgroundColor: "#ffffff" }}>
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
              >
                <TableRow>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Item Name</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Category</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Warranty</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Life Span</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {isGettingEmailDetailsData ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              ) : machineDetailsData?.MachineItem?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        height: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Available
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                machineDetailsData?.MachineItem?.map(
                    (machine: any, index: any) => (
                      <TableRow
                        sx={{ background: "#ffffff", }}
                      >
                        <TableCell
                          className="tablebodycellcomment"
                          sx={{
                            maxWidth: "500px",
                            wordBreak: "break-word",
                          }}
                        >
                          {machine.itemMaster.name
                            ? machine?.itemMaster.name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycellcomment"
                          sx={{
                            maxWidth: "500px",
                            wordBreak: "break-word",
                          }}
                        >
                          {machine.itemMaster.category
                            ? machine.itemMaster.category
                            : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycellcomment"
                          sx={{
                            maxWidth: "500px",
                            wordBreak: "break-word",
                          }}
                        >
                          {machine.itemMaster.warranty
                            ? `${machine?.itemMaster.warranty} months`
                            : "NA"}
                        </TableCell>
                        <TableCell
                          className="tablebodycellcomment"
                          sx={{
                            maxWidth: "500px",
                            wordBreak: "break-word",
                          }}
                        >
                          {machine.itemMaster.lifespanInDays ? `${machine?.itemMaster.lifespanInDays} days` : "NA"}
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
            {/* <Pagination
              emailTrackData={getCampaignDetailsData}
              handleChangePage={handleChangePage}
              handleLimitChange={handleLimitChange}
              pageChange={pageChange}
              limit={limit}
            /> */}
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getEmailDetailsData: dashboardEntity.getDashboard(state).getEmailDetailsData,
    isGettingEmailDetailsData: dashboardUI.getDashboard(state).isGettingEmailDetailsData,
    isGettingContactsDetailsData: contactDataUI.getContacts(state).isGettingContactsDetailsData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineDetails);
