import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { 
    ADD_MACHINE_DATA,
    GET_MACHINE_DATA,
    ADD_ITEM_DATA,
    GET_MACHINE_NAMES,
    EDIT_MACHINE_DATA,
    EDIT_ITEM_DATA,
    GET_ITEM_DATA,
    GET_MACHINE_ALLOCATION_DATA,
    ALLOCATE_MACHINE_DATA,
 } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, } = actionTypes
const entity = () => {
    const getAddMachineData = (state = [], action: actions) => {
        switch (action.type) {
          case ADD_MACHINE_DATA[SUCCESS]:
            return action.payload
          case ADD_MACHINE_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }

      const getEditMachineData = (state = [], action: actions) => {
        switch (action.type) {
          case EDIT_MACHINE_DATA[SUCCESS]:
            return action.payload
          case EDIT_MACHINE_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }
      
      const getAddItemData = (state = [], action: actions) => {
        switch (action.type) {
          case ADD_ITEM_DATA[SUCCESS]:
            return action.payload
          case ADD_ITEM_DATA[REQUEST]:
            return []
          default:
            return state
        }
      } 

      const getEditItemData = (state = [], action: actions) => {
        switch (action.type) {
          case EDIT_ITEM_DATA[SUCCESS]:
            return action.payload
          case EDIT_ITEM_DATA[REQUEST]:
            return []
          default:
            return state
        }
      } 

      const getMachineData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_MACHINE_DATA[SUCCESS]:
            return action.payload
          case GET_MACHINE_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }  

      const getMachineAllocationData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_MACHINE_ALLOCATION_DATA[SUCCESS]:
            return action.payload
          case GET_MACHINE_ALLOCATION_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }  

      const getItemData = (state = [], action: actions) => {
        switch (action.type) {
          case GET_ITEM_DATA[SUCCESS]:
            return action.payload
          case GET_ITEM_DATA[REQUEST]:
            return []
          default:
            return state
        }
      } 
      
      const getAllocateMachineData = (state = [], action: actions) => {
        switch (action.type) {
          case ALLOCATE_MACHINE_DATA[SUCCESS]:
            return action.payload
          case ALLOCATE_MACHINE_DATA[REQUEST]:
            return []
          default:
            return state
        }
      }  

      const getMachineNames = (state = [], action: actions) => {
        switch (action.type) {
          case GET_MACHINE_NAMES[SUCCESS]:
            return action.payload
          case GET_MACHINE_NAMES[REQUEST]:
            return []
          default:
            return state
        }
      }  

  return combineReducers({
    getAddMachineData,
    getMachineData,
    getAddItemData,
    getMachineNames,
    getEditMachineData,
    getEditItemData,
    getItemData,
    getMachineAllocationData,
    getAllocateMachineData,
  })
}

export default entity
export const getMachine = (state: RootState) => state.entities.machine
