import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { connect } from "react-redux";
import {
  addMachineDataAPICall,
  editMachineDataAPICall,
  getAddUserDataAPICall,
  getManagerDataAPICall,
} from "../../actions";
import { dashboardEntity } from "../../reducers";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: "red"
  }
}));

const EditMachines = (props: any) => {
  const {
    editMachineDataAPICall,
    handleEditMachineDialogClose,
    editMachineData,
  } = props;
  console.log("editMachineData",editMachineData);
  

  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedToDate1, setSelectedToDate] = useState(
    moment().add(10, "days").format("YYYY-MM-DD")
  );

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      disabled: {
        cursor: "not-allowed",
      },
      datepicker1: {
        width: "425px",
        borderRadius: "20px",
      },
      datepicker2: {
        width: "418px",
        borderRadius: "20px",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "#ffffff",
        },
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#add8e6",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 4% !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "0px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "10% !important",
        wordBreak: "break-word",
      },
    });
  });

  const classes = useStyles();

  const currentDate = selectedFromDate
    ? new Date(selectedFromDate)
    : new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDateSelected = dayjs(formattedCurrentDate);

  const toDateToday = selectedToDate1 ? new Date(selectedToDate1) : new Date();
  const formattedToDateToday = toDateToday.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDateSelected = dayjs(formattedToDateToday);

  const handleFromDateChange = (e: any) => {
    setSelectedFromDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleToDateChange = (e: any) => {
    setSelectedToDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const formik = useFormik({
    initialValues: {
      machineName: editMachineData?.name || "",
      modelNumber: editMachineData?.modelNumber || "",
      maintenance: editMachineData?.defaultAMCInDays || "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      machineName: Yup.string().required("Machine Name is required"),
      modelNumber: Yup.string().required("Model Number is required"),
      maintenance: Yup.number()
        .typeError("AMC must be a number")
        .min(0, "AMC must be a positive number"),
    }),
    onSubmit: (values, event: any) => {},
  });

  const handleSubmit = () => {
    let formdata = {
      name: formik.values?.machineName,
      modelNumber: formik.values?.modelNumber,
      defaultAMCInDays: formik.values?.maintenance,
      machineId: editMachineData?.id, 
    };
    if (formdata) {
      editMachineDataAPICall(formdata);
      handleEditMachineDialogClose();
    }
  };

  const getErrorText = (error: any): string | undefined => {
    return typeof error === "string" ? error : undefined;
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="machineName"
                name="machineName"
                label="Machine Name"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.machineName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.machineName && Boolean(formik.errors.machineName)}
                helperText={
                  formik.touched.machineName ? getErrorText(formik.errors.machineName) : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
          <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="modelNumber"
                name="modelNumber"
                label="Model Number"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.modelNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.modelNumber && Boolean(formik.errors.modelNumber)}
                helperText={
                  formik.touched.modelNumber ? getErrorText(formik.errors.modelNumber) : ""
                }
              />
            </div>
          </Grid>
        </Grid>
   
        <Grid container spacing={2}>

          <Grid item xs={6}>
          <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="maintenance"
                name="maintenance"
                label="Annual Maintenance Contract"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.maintenance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.maintenance && Boolean(formik.errors.maintenance)}
                helperText={
                  formik.touched.maintenance ? getErrorText(formik.errors.maintenance) : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            className={classes.cancelbtn}
            color="primary"
            onClick={() => handleEditMachineDialogClose()}
          >
            Cancel
          </Button>
          <span style={{ cursor: "not-allowed" }}>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              type="submit"
              // disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </span>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAddUserData: dashboardEntity.getDashboard(state).getAddUserData,
    getManagerNameData: dashboardEntity.getDashboard(state).getManagerData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    editMachineDataAPICall: (data: any) =>
      dispatch(editMachineDataAPICall.request(data)),
    getManagerNameDataAPICall: (data: any) =>
      dispatch(getManagerDataAPICall.request(data)),
    getAddUserDataAPICall: (data: any) =>
      dispatch(getAddUserDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMachines);
