import {
  FormControl,
  MenuItem,
  Pagination as MuiPagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useState } from "react";

const Pagination = (props: any) => {
  const {
    emailTrackData,
    handleChangePage,
    pageChange,
    handleLimitChange,
    limit,
  } = props;
  const [page, setPage] = useState<string>("");
  const [previous, setPrevious] = useState(0);

  const checkPagination = () => {
    if (emailTrackData?.totalPages > 0) {
      return "pagination-required";
    } else {
      return "pagination-not-required";
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value=(event.target as HTMLInputElement).value?.trim()
    if (event.key === "Enter" && value) {
      handleChangePage(null, value)
      event.preventDefault(); 
    }
  };

  const onPageChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(value.trim());
    if (!Number.isNaN(num) && num >= 0 && num <= emailTrackData?.totalPages)
      setPage(() => (num ? num?.toString() : ""));
  };

  switch (checkPagination()) {
    case "pagination-not-required":
      return <></>;
    case "pagination-required":
      return (
        <div className="page-cont">
          <div className="page-items">
            <Stack>
              <MuiPagination
                size="small"
                count={emailTrackData?.totalPages}
                variant="outlined"
                color="primary"
                page={pageChange}
                onChange={handleChangePage}
                renderItem={(item) => {
                  if (item.type === "previous") setPrevious(Number(item.page));
                  if (previous + 1 === item.page) item.selected = true;
                  return <PaginationItem {...item} />;
                }}
              />
            </Stack>
          </div>
          <TextField
            id="page"
            name="page"
            onChange={onPageChange}
            placeholder="Page"
            onKeyDown={handleKeyDown}
            value={page}
            sx={{
              margin: "0px 0px 0px 10px",
              width: "70px !important",
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                borderRadius: "20px",
              },
              "& input": { height: "17px" },
            }}
          />
          <FormControl
            sx={{
              height: "50%!important",
              width: "6% !important",
              marginLeft: "10px !important",
            }}>
            <Select
              sx={{ borderRadius: "20px", fontSize: "10px" }}
              labelId="limit-select-label"
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "150px",
                  },
                },
              }}>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={150}>150</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
  }
};

export default Pagination;
